import { useState, useEffect } from "react";
import API from "../../api";
import ActionTypes from "../../store/actionTypes";
import { useCentralStore } from "../../store/appContext";
import resources from '../../components/common/resources.json'
import moment from "moment";
import SGPReportsFilter from "../operationalReport/SGPReportsFilter";
import { isEmpty } from "../../utils/functionUtils";
import SHDashboardMenu from "./SHDashboardMenu";
import SHDashboardData from "./SHDashboardData";
import Popup from "../../components/common/popup/popup";
import DownloadIcon from '../../assets/icons/downloadIcon'
import exportToCSVInventory from "../../components/common/hepler/export-to-csv-submatricsData";
import ProgramSummary from "./ProgramSummary";
import Definitions from "../../pages/dashboard/shDefinitions";
import AsurionLogo from '../../assets/icons/asurionLogo'
import { toPascalCase, setCursorPositioning } from "../../utils/functionUtils";
import FinanceReconReport from "./FinanceReconReport";
import SlaReport from "./SlaReport";
import SLAReportContainer from "./SLAReport/SLAReportContainer";
import InfoIcon from '../../assets/icons/infoIcon'
import GlossaryReport from "./GlossaryReport"
import DownloadReports from "./DownloadReports";
import TrendCompoent from "./StarHubReports/TrendCompoent";

const StarhubContainer = () => {

    const [ { reportFilterDuration, userData, userDetails, selectedTab, projectName, selectedReportFilter, hideDateFilter, reportCountSuccess, chartReportCountSuccess, programName }, dispatch] = useCentralStore()

    const setLoading = (action) => {
        dispatch({ type: ActionTypes.SET_LOADING, payload: action });
      };

    useEffect(() => {
        if(!isEmpty(reportFilterDuration?.duration) && (!isEmpty(reportFilterDuration?.endDate) || !isEmpty(reportFilterDuration?.startDate) || !isEmpty(reportFilterDuration?.type)) && projectName === 'Real Time Service Request Report') {
        let payload = {
          StartDate: reportFilterDuration?.startDate,
          EndDate: reportFilterDuration?.endDate,
          // StartDate: '2023-05-01 00:00:00',
          // EndDate: '2023-05-31 23:59:59',
          ReportType: 'All'
      };
      setLoading(true)
      dispatch({
        type: ActionTypes.SET_DASHBOARD_LOADING,
        payload: true,
      });
      API[ActionTypes.GET_STARHUB_REPORT_COUNT](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
          setLoading(false);
          if (data) {
            dispatch({
              type: ActionTypes.SET_DASHBOARD_LOADING,
              payload: false,
            });
            dispatch({
              type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS,
              payload: data,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: ActionTypes.GET_STARHUB_REPORT_COUNT_FAILURE,
            payload: err,
          });
          dispatch({
            type: ActionTypes.SET_DASHBOARD_LOADING,
            payload: false,
          });
          dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
          let _payload = {
            level: resources.POST_LOGGER_ERROR,
            category: resources.POST_LOGGER_API,
            ApiName: resources.POST_LOGGER_APINAME,
            message: {
              error: err,
              info: {
                idToken: "",
              },
            },
          };
          API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
        });
        }
      }, [reportFilterDuration])

      const downloadTrendSRData = () => {
        const resDataKeys = ['NPSCount', 'EnrollmentReportCount', 'ServiceRequestRevenueCount', 'ServiceRequestReportCount']

        for(let r = 0; r<resDataKeys?.length; r++){
          const colLables = []
          const totalKeys = {};
          chartReportCountSuccess?.[resDataKeys[r]]?.reduce((acc, current) => {
            const keys = Object.keys(current);
            for (var i in keys) {
              totalKeys[keys[i]] = 1;
            }
          }, totalKeys);
  
          var keys = Object.keys(totalKeys);
          for (let i in keys) {
            colLables.push({
              title: keys[i]?.replace(/([a-z0-9])([A-Z])/g, "$1 $2")?.toUpperCase(),
              dataIndex: keys[i],
              key: keys[i],
              width: 280
            });
          }
  
          exportToCSVInventory(
            colLables,
            chartReportCountSuccess?.[resDataKeys[r]],
            "",
            "",
            `${resDataKeys[r]?.replace('EnrollmentReportCount', 'Subscription')?.replace('Report', '')?.replace('Count', '')} Report`,
            dispatch,
            ActionTypes
        )
        }

        // console.log('download chartReportCountSuccess', chartReportCountSuccess, keys);
      }
      useEffect(() => {
          if(reportFilterDuration && (projectName === "Customer Service SLA Report" || projectName === 'Technical Service SLA Report')){
            let payload = {
              idToken: userData?.access_token,
              month: reportFilterDuration?.duration
            };
      
            setLoading(true);  
            API[ActionTypes.GET_SLA_REPORTS_DETAILS](payload, userDetails && userDetails?.email?.split("@")?.[0])
              .then(({ data }) => {
                setLoading(false);  
                if (data) {
                  dispatch({
                    type: ActionTypes.GET_SLA_REPORTS_DETAILS_SUCCESS,
                    payload: data,
                  });
                }
              })
              .catch((err) => {
                dispatch({
                  type: ActionTypes.GET_SLA_REPORTS_DETAILS_FAILURE,
                  payload: err,
                });
                dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
                let _payload = {
                  level: resources.POST_LOGGER_ERROR,
                  category: resources.POST_LOGGER_API,
                  ApiName: resources.POST_LOGGER_APINAME,
                  message: {
                    error: err,
                    info: {
                      idToken: "",
                    },
                  },
                };
                API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
              }
            )
          }
      }, [reportFilterDuration, projectName])
     
      const [animate, setAnimate] = useState(null);
      const [isOpen, openPopup] = useState(false);
      const handleOpen = (e) => {
        setCursorPositioning(e);
        setAnimate("modal-space-in");
        openPopup(true);
      };
      const handleClose = () => {
        setAnimate("modal-space-out");
        setTimeout(() => openPopup(false), 1000);
      };   

      const [classInOut, setClassInOut] = useState(null);

    return(
        <>
        <div className="">
          {/* {selectedTab === "Prev Month" && 
            <a
              className="float-right w-auto mt-1 ml-5 mr-20 text-xs cursor-pointer"
              onClick={handleOpen}
            >
              <InfoIcon className="flex w-3 h-3" fill="green" />
            </a>
          }
          {isOpen && (
            <Popup
              show={isOpen}
              global={true}
              className={`${animate}`}
              fitContent={true}
              onClose={handleClose}
              definations={true}
            >
              <Definitions onClose={handleClose} selectedTab={selectedTab} />
            </Popup>
          )} */}

          <div className="flex justify-between items-center mx-[40px]">
            <SHDashboardMenu />
            {/* {(selectedTab !== 'Monthly Trends' && selectedTab !== 'SLA calculation methodology' && projectName !== "Finance Reconciliation & Invoice" && selectedTab !== "Glossary") && */}
            {(selectedTab !== 'Monthly Trends' && selectedTab !== 'SLA calculation methodology' && projectName !== 'Program Summary' && projectName !== "Finance Reconciliation & Invoice" && selectedTab !== "Glossary") &&
                <SGPReportsFilter 
                    selectedReportFilter={selectedReportFilter} 
                    dispatch={dispatch}
                    ActionTypes={ActionTypes}
                />
            }
            {(projectName === 'Real Time Service Request Report' && selectedTab === 'Monthly Trends') &&
              <div className="flex">
                  <div className={`cursor-pointer py-0 pr-3 pl-1 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
                      selectedTab === 'HomePage' ? "bg-app-lightGreen font-bold rounded-lg" : "" }`}>
                  <div className="flex items-center p-3" 
                  onClick={() => downloadTrendSRData()}
                  >
                  <DownloadIcon className="" fill={"#1ED760" }
                      />
                      <div className={`text-black text-xs ml-2`}>
                          Download
                      </div>
                  </div>
                </div>
            </div>
            }
          </div>
        {((selectedTab === 'Real Time' || selectedTab === 'DownloadReport') && reportCountSuccess) &&
          <SHDashboardData />
        }
        {(programName === 'Real Time Service Request Report' && selectedTab === 'Monthly Trends' && chartReportCountSuccess) &&
          <TrendCompoent />
        }
        {(projectName === "Customer Service SLA Report" ||  projectName === "Technical Service SLA Report") &&
          <SLAReportContainer />
        }
        {projectName === "Program Summary" &&
          <ProgramSummary />
        }
        {projectName === "Finance Reconciliation & Invoice" && selectedTab !=="SLA Report" && selectedTab !== "Glossary" &&
          <FinanceReconReport/>
        }
        {projectName === "Finance Reconciliation & Invoice" && selectedTab ==="SLA Report" &&
          <SlaReport/>
        }
        {projectName === "Real Time Service Request Report" && selectedTab ==="Glossary" &&
          <GlossaryReport selectedTab={selectedTab} projectName={projectName}/>
        }
        {projectName === "Finance Reconciliation & Invoice" && selectedTab ==="Glossary" &&
          <GlossaryReport selectedTab={selectedTab} projectName={projectName}/>
        }
      </div>
      <Popup
        show={selectedTab === 'DownloadReport'}
        global={true}
        className={`rounded-xl ${classInOut}`}
        fitContent={true}
        // onClose={() => openPopup(false)}
        downloadReport={true}
        >
        <DownloadReports />
        </Popup>
      <div className="fixed bottom-0 right-[2%]">
          <AsurionLogo className="" />
      </div>
    </>
    )
}

export default StarhubContainer