import ProgressBar from "../../common/progressBar/progressBar";
import PerformanceCard from "../performanceCard/performanceCard";
import { getMyColors, isEmpty, setCursorPositioning  } from "../../../utils/functionUtils";
import DashboardArrow from "../../../assets/icons/dashboardArrow";
import ArrowDiv from "../../../assets/icons/arrowDiv";
import React, { useEffect, useState } from "react";
import PlusSignIcon from '../../../assets/icons/plusSignIcon'
import MinusSignIcon from '../../../assets/icons/minusSignIcon'
import RecursiveComponent from "../recursion/recursion";
import ActionTypes from "../../../store/actionTypes";
import Popup from "../popup/popup";
import DataTable from "../dataTable/dataTable";
import API from "../../../api";
import { useCentralStore } from "../../../store/appContext";
import moment from "moment";
import CrossIcon from '../../../assets/icons/cross-icon'
import config from "../../../config/config";
// import SGPPerformanceCard from "../performanceCard/SGPPerformanceCard";

const PerformanceBar = ({
  barObject,
  performanceIndex,
  nextIndex,
  hideLastArrow,
  projectData,
  nintyDaysData,
  selectedTab,
  goToMatricsTier,
  matricsObj,
  matricsColor,
  matricsColors,
  resources,
  submetrics,
  // getSubData,
  // isOpen,
  // classInOut,
  // submatricsDetailsData,
  // selectedDate,
  // subquerykey,
  // openPopup1,
  // setClassInOut
  // prevLabel,
  clientData,
  selectedClient,
  selectedStore,
  dispatch,
  userData,
  submatricsDetailsData,
  selectedDate
}) => {
  const [index, setIndex] = useState(0);
  const colors = getMyColors(index || performanceIndex);

  const [ {updatedSubmatricsData, userDetails, selectedDashboard, childSubmatricsData, mapSelected } ] = useCentralStore()

  const getTextColor = () => {
    let color = "#8223D2";
    switch (colors?.primary) {
      case "#8223D2":
        return `text-purple-color`;
      case "#6E767D":
        return `text-grey-color`;
      case "#00B574":
        return `text-green-color`;
      case "#4A68F9":
        return `text-blue-color`;
      case "#82A700":
        return `text-yellow-color`;
      case "#A839F3":
        return `text-light-blue-color`;
      case "#9F0000":
        return `text-red-color`;
      default:
        return `text-purple-color`;
    }
  };

  const getBgColor = () => {
    let color = "#8223D2";
    switch (colors?.primary) {
      case "#8223D2":
        return `bg-purple-color`;
      case "#6E767D":
        return `bg-grey-color`;
      case "#00B574":
        return `bg-green-color`;
      case "#4A68F9":
        return `bg-blue-color`;
      case "#82A700":
        return `bg-yellow-color`;
      case "#A839F3":
        return `bg-light-blue-color`;
      case "#9F0000":
        return `bg-red-color`;
      default:
        return `bg-purple-color`;
    }
  };

  const getTextSecondaryColor = () => {
    let color = "#8223D2";
    switch (colors?.secondary) {
      case "#D648FD":
        return `text-purple-secondary-color`;
      case "#D5D6DA":
        return `text-grey-secondary-color`;
      case "#1DFFBA":
        return `text-green-secondary-color`;
      case "#00B9FD":
        return `text-blue-secondary-color`;
      case "#D2FA46":
        return `text-yellow-secondary-color`;
      case "#A839F3":
        return `text-light-blue-color`;
      case "#32E7FF":
        return `text-light-blue-secondary-color1`;
      case "#3CAFF7":
        return `text-dark-blue-secondary-color`;
      default:
        return `text-purple-color`;
    }
  };

  const textColor = getTextColor();
  const bgColor = getBgColor();
  const textSecondaryColor = getTextSecondaryColor();

  const nextIndexColor = getMyColors(performanceIndex.length!==0 ? nextIndex:1);
  const percentage = (100 * Number(barObject?.value)) / barObject?.maxValue;

  const [on, setOnState] = useState(false);
  const toggle = () => setOnState(o => !o);

  const [isOpen, openPopup] = useState(false);
  const [classInOut, setClassInOut] = useState(null);
  const [subquerykey, setSubquerykey] = useState(null);
  const [metricsLabel, setMetricsLabel] = useState(null);
  const [value, setValue] = useState(null);
  const [label, setLabel] = useState(null);
  const [_colors, setColors] = useState(null);
  const [childSubmetrics90DaysData, setChildSubmetrics90DaysData] = useState(null);
  const [isfailurePopupOpen, openFailurePopup] = useState(false);
  
  const openPopup1 = () => {
    setTimeout(() => {
      openPopup(false);
      dispatch({
        type: ActionTypes.GET_SUBMATRICS_DETAILS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_SUCCESS,
        payload: undefined,
      });
    }, [2000]);
  };

  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
  };

  const getSubData = async (e, subquerykey, MetricsLabel, value, label, colors, tier) =>{

    let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);
      let payload = {
        DashboardId: selectedStore?.toLowerCase() == 'care' ? 'all' : selectedDashboard,
        DashboardName: selectedTab,
        Channel: selectedStore?.toLowerCase() == 'care' ? 'all' : selectedStore,
        ClientId: selectedClient,
        Client: getClientName?.Client,
        Duration: selectedDate == resources.LAST_30_DAYS_VALUE || selectedDate == resources.LAST_60_DAYS_VALUE ? selectedDate : resources.LAST_90_DAYS_VALUE,
        MetricsLabel: submetrics?.Metrics[0]?.label,
        // Username: 'cwr.sagar.gadwe'
        Username: userDetails && userDetails?.email?.split("@")?.[0]
      }
      setLoading(true);
      API[ActionTypes.GET_CHILD_SUBMATRICS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        setLoading(false);
        if(data){
          setLoading(false);
          // dispatch({
          //   type: ActionTypes.GET_CHILD_SUBMATRICS_SUCCESS,
          //   payload: data?.getChildSubMetricsResponse,
          // });
          setSubquerykey(subquerykey)
          setMetricsLabel(submetrics?.Metrics[0]?.label)
          setValue(value)
          setLabel(label)
          setColors(colors)

          setCursorPositioning(e)
          let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);

          // function getMetric(dataset, key) {
          //   let found = false;
          //   for(let i=0; i <dataset.submetrics.length;i++) {

          //     // If submetrics is an array
          //     if(Array.isArray(dataset.submetrics[i])) {
          //       for(let j = 0; j < dataset.submetrics[i].length; j++) {
          //         if(dataset.submetrics[i][j].label == key){
          //           found = true
          //           return dataset.submetrics[i][j];
          //         }
          //       }
          //     }

          //     // If submetrics is an object
          //     if(dataset.submetrics[i].label == key){
          //       return dataset.submetrics[i]
          //     }
          //   }

          //   if(!found) {
          //     const nextLevelDataset = dataset.submetrics.map(e => {
          //       return e.submetrics;
          //     })
          //     return getMetric({ submetrics: nextLevelDataset }, key);
          //   }
          // }

          const getMembers = (members) => {
            let children = [];
          
            return members.map(m => {
              if (m.submetrics && m.submetrics.length) {
                children = [...children, ...m.submetrics];
              }
              return m;
            }).concat(children.length ? getMembers(children) : children);
          };
          
          const __data = getMembers(data?.getChildSubMetricsResponse?.Metrics[0].submetrics)?.find(e=> e.label === subquerykey)
          setChildSubmetrics90DaysData(__data)

          const payload = {
            StartDate: data?.getChildSubMetricsResponse && data?.getChildSubMetricsResponse?.Duration?.StartDate,
            EndDate: data?.getChildSubMetricsResponse && data?.getChildSubMetricsResponse?.Duration?.EndDate,
            QueryKey: subquerykey,
            StartingRow: 1,
            RowsCount: 10,
            Channel:
              selectedStore?.toUpperCase() === "STORE" ? "IN-STORE" : selectedStore,
            Duration: data?.getChildSubMetricsResponse && data?.getChildSubMetricsResponse?.Duration?.Duration,
            TotalCount: Math.round(Number(__data?.value))?.toString(),
            MetricsLabel: submetrics?.Metrics[0]?.label,
            Client: getClientName?.Client,
          };
          setLoading(true);
          API[ActionTypes.GET_SUBMATRICS_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
            .then(({ data }) => {
              setLoading(false);
              if (data) {
                // setRefreshedOn(moment().format("YYYY-MM-DD HH:mm:ss"));
                openPopup(true);
                setClassInOut("modal-container modal-space-in");
                dispatch({
                  type: ActionTypes.GET_SUBMATRICS_DETAILS_SUCCESS,
                  payload: data?.GetSubMetricsDetailsResponse,
                });
              }
            })
            .catch((err) => {
              setLoading(false);
              openFailurePopup(true);
              setClassInOut("modal-container modal-space-in");
              dispatch({
                type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
                payload: err,
              });
              // dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
              let _payload = {
                level: resources.POST_LOGGER_ERROR,
                category: resources.POST_LOGGER_API,
                ApiName: resources.POST_LOGGER_APINAME,
                message: {
                  error: err,
                  info: payload,
                },
              };
              API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);

              let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);
              // let last60Days = moment().subtract(60, 'days').calendar()

              const payloadEmail = {
                // StartDate: moment(last60Days).format(),
                StartDate: projectData?.Duration?.StartDate,
                EndDate: projectData?.Duration?.EndDate,
                QueryKey: subquerykey,
                Channel:
                  selectedStore?.toUpperCase() === "STORE" ? "IN-STORE" : selectedStore,
                Email: userDetails?.email,
                TotalCount: value?.toString(),
                MetricsLabel: MetricsLabel,
                Client: getClientName?.Client,
                // Duration: resources.LAST_60_DAYS_VALUE
                Duration: projectData?.Duration?.Duration,
                isFailureScenario: true,
                isExport: true
              };
              setLoading(true);
              API[ActionTypes.GET_SUBMATRICS_DETAILS](payloadEmail, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
                .then(({ data }) => {
                  setLoading(false);
                  if (data) {
                    dispatch({
                      type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_SUCCESS,
                      payload: data,
                    });
                  }
                })
                .catch((err) => {
                  setLoading(false);
                  dispatch({
                    type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
                    payload: err,
                  });
                  // dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
                  let _payload = {
                    level: resources.POST_LOGGER_ERROR,
                    category: resources.POST_LOGGER_API,
                    ApiName: resources.POST_LOGGER_APINAME,
                    message: {
                      error: err,
                      info: payloadEmail,
                    },
                  };
                  API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
                });
            });
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: ActionTypes.GET_CHILD_SUBMATRICS_FAILURE,
          payload: err,
        });
        // dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: payload,
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      })
    }

  // const getSubData = (e, subquerykey, MetricsLabel, value, label, colors) => {
  //   setSubquerykey(subquerykey)
  //   setMetricsLabel(submetrics?.Metrics[0]?.label)
  //   setValue(value)
  //   setLabel(label)
  //   setColors(colors)

  //   setCursorPositioning(e)
  //   let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);
  //   const NintyDaysCount = nintyDaysData?.Metrics?.find((e) => e.label === MetricsLabel)?.submetrics?.find((e) => e.label === subquerykey)?.value
  //   const payload = {
  //     StartDate: selectedDate === "LAST_30_DAYS" || selectedDate === "LAST_60_DAYS" ? projectData?.Duration?.StartDate : nintyDaysData?.Duration?.StartDate,
  //     EndDate: selectedDate === "LAST_30_DAYS" || selectedDate === "LAST_60_DAYS" ? projectData?.Duration?.EndDate : nintyDaysData?.Duration?.EndDate,
  //     QueryKey: subquerykey,
  //     StartingRow: 1,
  //     RowsCount: 10,
  //     Channel:
  //       selectedStore?.toUpperCase() === "STORE" ? "IN-STORE" : selectedStore,
  //     Duration: selectedDate === "LAST_30_DAYS" || selectedDate === "LAST_60_DAYS" ? projectData?.Duration?.Duration : nintyDaysData?.Duration?.Duration,
  //     TotalCount: selectedDate === "LAST_30_DAYS" || selectedDate === "LAST_60_DAYS" ? Math.round(Number(value))?.toString() : NintyDaysCount.round(Number(value))?.toString(),
  //     MetricsLabel: submetrics?.Metrics[0]?.label,
  //     Client: getClientName?.Client,
  //   };
  //   setLoading(true);
  //   API[ActionTypes.GET_SUBMATRICS_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
  //     .then(({ data }) => {
  //       setLoading(false);
  //       if (data) {
  //         // setRefreshedOn(moment().format("YYYY-MM-DD HH:mm:ss"));
  //         openPopup(true);
  //         setClassInOut("modal-container modal-space-in");
  //         dispatch({
  //           type: ActionTypes.GET_SUBMATRICS_DETAILS_SUCCESS,
  //           payload: data?.GetSubMetricsDetailsResponse,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       dispatch({
  //         type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
  //         payload: err,
  //       });
  //       // dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
  //       let _payload = {
  //         level: resources.POST_LOGGER_ERROR,
  //         category: resources.POST_LOGGER_API,
  //         ApiName: resources.POST_LOGGER_APINAME,
  //         message: {
  //           error: err,
  //           info: payload,
  //         },
  //       };
  //       API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);

  //       let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);
  //       // let last60Days = moment().subtract(60, 'days').calendar()

  //       const payloadEmail = {
  //         // StartDate: moment(last60Days).format(),
  //         StartDate: projectData?.Duration?.StartDate,
  //         EndDate: projectData?.Duration?.EndDate,
  //         QueryKey: subquerykey,
  //         Channel:
  //           selectedStore?.toUpperCase() === "STORE" ? "IN-STORE" : selectedStore,
  //         Email: userDetails?.email,
  //         TotalCount: value?.toString(),
  //         MetricsLabel: MetricsLabel,
  //         Client: getClientName?.Client,
  //         // Duration: resources.LAST_60_DAYS_VALUE
  //         Duration: projectData?.Duration?.Duration,
  //         isFailureScenario: true,
  //         isExport: true
  //       };
  //       setLoading(true);
  //       API[ActionTypes.GET_SUBMATRICS_DETAILS](payloadEmail, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
  //         .then(({ data }) => {
  //           setLoading(false);
  //           if (data) {
  //             dispatch({
  //               type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_SUCCESS,
  //               payload: data,
  //             });
  //           }
  //         })
  //         .catch((err) => {
  //           setLoading(false);
  //           dispatch({
  //             type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
  //             payload: err,
  //           });
  //           dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
  //           let _payload = {
  //             level: resources.POST_LOGGER_ERROR,
  //             category: resources.POST_LOGGER_API,
  //             ApiName: resources.POST_LOGGER_APINAME,
  //             message: {
  //               error: err,
  //               info: payloadEmail,
  //             },
  //           };
  //           API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
  //         });
  //     });
  //   // .finally(() => {
  //   //   setLoading(false);
  //   // });
  // }

  const [noOfLevel, setNoOfLevel] = useState(0);
  const [marginRight, setMarginRight] = useState(0);

  const getNoOfSubDataLevel = arr => {
    return Array.isArray(arr) ? 1 + Math.max(...arr.map(getNoOfSubDataLevel)) : typeof(arr) === 'object' ? Math.max(...Object.values(arr).map(getNoOfSubDataLevel)): 0
  };

  useEffect(() => {
    if(submetrics?.Metrics?.[0]?.label === barObject?.label){
      setNoOfLevel(getNoOfSubDataLevel(submetrics?.Metrics?.[0].submetrics))
    }
  }, [submetrics, barObject])

  useEffect(() => {
      setNoOfLevel(getNoOfSubDataLevel(submetrics?.Metrics?.[0].submetrics))
  }, [updatedSubmatricsData])

  useEffect(() => {
    if(noOfLevel > 0) {
      let margin = (noOfLevel - 1) * 280
      setMarginRight(margin)
    }
  }, [noOfLevel])

  const openPopup2 = () => {
    setTimeout(() => {
      openFailurePopup(false);
      dispatch({
        type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
        payload: [],
      });
      dispatch({
        type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_FAILURE,
        payload: undefined,
      });
    }, [2000]);
  };

  // useEffect(() => {
  //   if(submetrics?.Metrics?.[0]?.label === barObject?.label){
  //     const element = document.getElementById(barObject?.label);
  //       if (element) {
  //         // 👇 Will scroll smoothly to the top of the next section
  //         element.scrollIntoView({ block: "end" });
  //       }
  //   }
  // }, [submetrics?.Metrics?.[0]?.label === barObject?.label])

  return (
    // <div className={`relative ${submetrics?.Metrics?.[0]?.label == undefined ? 'opacity-100' : submetrics?.Metrics?.[0]?.label === barObject?.label ? 'opacity-100' : 'opacity-40'}`}
    <div className={`relative mt-8`} id={barObject?.label}
    style={{ marginRight: submetrics?.Metrics?.[0]?.label === barObject?.label ? marginRight+'px' : '' }}>
    {/* <div style={{ marginRight: submetrics?.Metrics?.[0]?.label === barObject?.label ? marginRight+'px' : '' }}> */}
      {/* <div className={`p-2 ${matricsObj ? 'w-56' : 'w-3/4'} mx-5`}> */}
      <div className={`p-2 w-60 ${matricsObj ? "mx-10" : "mx-8"}`}>
        <ProgressBar
          value={percentage > 100 ? 100 : (percentage <= 0 || isNaN(percentage)) ? 0 : percentage}
          label={barObject.value}
          description={barObject.metricLabel}
          primaryColor={colors.primary}
          secondaryColor={matricsColors?.secondary || colors.secondary}
          barLabel={barObject?.label}
          metricsTypeIndicator={barObject?.MetricsTypeIndicator}
          textSecondaryColor={textSecondaryColor}
          mapSelected={mapSelected}
        />
        <div className="relative">
          <div
            // className={`${
            //   selectedTab === resources.OPS_DASHBOARD_LBL
            //     ? "cursor-pointer"
            //     : ""
            // }`}
            // onClick={(e) =>
            //   selectedTab === resources.OPS_DASHBOARD_LBL
            //     ? (goToMatricsTier(e, matricsObj ? undefined : barObject),
            //       matricsColor(matricsObj ? undefined : colors),
            //       setIndex(performanceIndex),
            //     : undefined
            // }
          >
            <p
              className={`${config.isSGP === 'true' ? 'text-xs ' : 'text-sm'} absolute font-bold text-center`}
              style={{
                color: config.isSGP === 'true' ? 'black' : (matricsColors?.text || colors.text),
                top: "50%",
                right: "48%",
                transform: "translate(50%, -50%)",
              }}
            >
              {barObject?.label?.toUpperCase()}
            </p>
            <ArrowDiv
              className=""
              id={performanceIndex}
              primaryColor={config.isSGP === 'true' ? '#00BFB2' : (matricsColors?.primary || colors.primary)}
              secodaryColor={config.isSGP === 'true' ? '#03F182' : (matricsColors?.secondary || colors.secondary)}
              label={barObject?.label?.toUpperCase()}
            />
          </div>
          {!hideLastArrow && (
            <DashboardArrow
              className={`w-10 h-10 absolute right-m32% top-23%`}
              fill={config.isSGP === 'true' ? '#03F182' : nextIndexColor?.primary}
            />
          )}
        </div>

        {(selectedTab === resources.OPS_DASHBOARD_LBL || selectedTab === resources.HOME_PLUS_OPS_DASHBOARD_LBL || selectedTab === resources.STARHUB_OPS_DASHBOARD_LBL) &&
          <div className="flex justify-center">
            <div className={`flex flex-row items-center cursor-pointer`} 
              onClick={(e) => (goToMatricsTier(e, matricsObj && submetrics?.Metrics?.[0]?.label === barObject?.label ? undefined : barObject),
                matricsColor(matricsObj && submetrics?.Metrics?.[0]?.label === barObject?.label ? undefined : colors),
                setIndex(matricsObj && submetrics?.Metrics?.[0]?.label === barObject?.label ? undefined : performanceIndex))}>
              <p className={`${textColor} text-sm`}>{matricsObj && submetrics?.Metrics?.[0]?.label === barObject?.label ? resources.COLLAPSE_SUBMATRICS_LBL : resources.EXPAND_SUBMATRICS_LBL } </p>&nbsp;
              {/* {!matricsObj ? <PlusSignIcon className="" fill={colors && colors?.primary} /> : <MinusSignIcon className="" fill={matricsColors && matricsColors?.primary} />} */}
              <button className={`${matricsObj && submetrics?.Metrics?.[0]?.label === barObject?.label ? 'on' : 'off'} toggleButton ${bgColor}`} on={on} onClick={toggle}>
                <span className="pin" />
              </button>
            </div>
          </div>
        }

      {(submetrics?.Metrics?.[0]?.label === barObject?.label) &&
        <>
        <RecursiveComponent submetrics1={submetrics?.Metrics[0].submetrics} dispatch={dispatch} ActionTypes={ActionTypes} barObject={submetrics?.Metrics[0]} colors={matricsColors} getSubData={getSubData} resources={resources}/>
          <Popup
            show={isOpen}
            global={true}
            className={`rounded-xl ${classInOut}`}
            fitContent={true}
            // onClose={() => openPopup(false)}
            submatricsDetailsData={submatricsDetailsData}
            submatrics={true}
          >
            {(isOpen && submatricsDetailsData) && (
              <DataTable
                submatricsDetailsData={submatricsDetailsData}
                selectedDate={selectedDate}
                subquerykey={subquerykey}
                label={label}
                isSubmetrics={true}
                childSubmetrics90DaysData={childSubmetrics90DaysData?.value}
                childSubmetrics90DaysDataExport={value}
                value={value}
                colors={colors}
                MetricsLabel={metricsLabel}
                onClose={(e) => ( openPopup1(), setClassInOut("modal-container modal-space-out"), e.stopPropagation()
                )}
                resources={resources}
              />
            )}
          </Popup>
          </>
        }

        <div className={`${submetrics?.Metrics?.[0]?.label == undefined ? 'opacity-100' : submetrics?.Metrics?.[0]?.label === barObject?.label ? 'opacity-100' : 'opacity-40 pointer-events-none'}`}>
          {(submetrics?.Metrics?.[0]?.label !== barObject?.label) &&
            barObject.submetrics &&
            barObject.submetrics.map((s) => (
              <PerformanceCard
                key={s.label}
                label={s.label}
                value={s.value}
                status={s.status || 'green'}
                colors={colors}
                subquerykey={s?.subquerykey}
                MetricsLabel={barObject?.label}
                barLabel={barObject?.label}
                subMetricsTypeIndicator={s?.SubMetricsTypeIndicator}
                resources={resources}
                cardData={barObject}
                reportType={barObject?.ReportType}
              />
            ))}
          {/* {config.isSGP === 'true' && (submetrics?.Metrics?.[0]?.label !== barObject?.label) &&
            barObject.submetrics &&
            barObject.submetrics.map((s) => (
              <SGPPerformanceCard
                key={s.label}
                label={s.label}
                value={s.value}
                status={s.status}
                colors={colors}
                subquerykey={s.subquerykey}
                MetricsLabel={barObject?.label}
                barLabel={barObject?.label}
                subMetricsTypeIndicator={s?.SubMetricsTypeIndicator}
                resources={resources}
              />
            ))} */}
        </div>
      </div>
      <Popup
        show={isfailurePopupOpen}
        global={true}
        className={`rounded-xl ${classInOut}`}
        fitContent={true}
        submatrics={true}
      >
        {isfailurePopupOpen && (
          <div className="flex flex-row gap-3 justify-between items-center h-20 px-4 py-4" onClose = {(e) => (openPopup2(), setClassInOut("modal-container modal-space-out"), e.stopPropagation())}>
            <p className="text-black text-base font-bold">{label}</p>
            <p className="text-black text-sm mt-4 mb-4">It is taking more time than expected to fetch the data. You will receive email with details shortly.!</p>
            <CrossIcon className="w-4 h-4 cursor-pointer" onClick={() => openFailurePopup(false)}/>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default PerformanceBar;
