import { useEffect, useState } from "react";
import Popup from "./../popup/popup";
import DataTable from "./../dataTable/dataTable";
import ActionTypes from "../../../store/actionTypes";
import { useCentralStore } from "../../../store/appContext";
import API from "../../../api";
import GreenDashboardCircle from "./../../../assets/icons/greenDashboardCircle";
import YellowDashboardCircle from "./../../../assets/icons/yellowDashboardCircle";
import RedDashboardCircle from "./../../../assets/icons/redDashboardCircle";
import { isEmpty, numberWithCommas, setCursorPositioning, getTypeIndicator } from "../../../utils/functionUtils";
import AnimatedNumbers from "react-animated-numbers";
import LoadingNumbers from '../../../assets/icons/loadingGif'
import PlusSignIcon from '../../../assets/icons/plusSignIcon'
import MinusSignIcon from '../../../assets/icons/minusSignIcon'
import moment from "moment";
import CrossIcon from "./../../../assets/icons/cross-icon";
import config from "../../../config/config";
import SGPDataTable from "../dataTable/SGPDataTable";
import InfoIcon from '../../../assets/icons/infoIcon'

const PerformanceCard = ({
  label,
  status,
  value,
  colors,
  subquerykey,
  MetricsLabel,
  barLabel,
  metricsCss,
  subMetricsTypeIndicator,
  className,
  id,
  isSubMatrics = false,
  getSubData,
  resources,
  showMinusIcon,
  submetrics,
  collapseMatrics,
  removedSubMetricData,
  cardData,
  reportType
}) => {
  const [
    {
      projectData,
      nintyDaysData,
      selectedDate,
      submatricsDetailsData,
      selectedStore,
      selectedClient,
      clientData,
      userData,
      dashboardLoading,
      removedSubMatricsData,
      userDetails,
      selectedDashboard,
      selectedTab,
      mapSelected,
      reportFilterDuration,
      reportDetailsSuccess
    },
    dispatch,
  ] = useCentralStore();
  const getBgBorderColor = () => {
    let color = "green";
    switch (status?.toLowerCase()) {
      case "green":
        return `bg-card-greenBg border-card-greenBorder shadow-card-greenBorder`;
      case "yellow":
        return `bg-card-yellowBg border-card-yellowBorder shadow-card-yellowBorder`;
      case "amber":
        return `bg-card-amberBg border-card-amberBorder shadow-card-amberBorder`;
      case "red":
        return `bg-card-redBg border-card-redBorder shadow-card-redBorder`;
      default:
        return `bg-${color}-100 border-${color}-500`;
    }
  };
  const bgColor = getBgBorderColor();

  const [isOpen, openPopup] = useState(false);
  const [isfailurePopupOpen, openFailurePopup] = useState(false);
  const [stopAnimation, setStopAnimation] = useState(false);
  const [classInOut, setClassInOut] = useState(null);

  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
  };

  // const getSumatrics = (e) => {
  //   // var d = new Date();
  //   // d.setDate(d.getDate() - 60);
  //   // moment().format("YYYY-MM-DD HH:mm:ss")
  //   let last60Days = moment().subtract(60, 'days').calendar()

  //   setCursorPositioning(e)
  //   const NintyDaysCount = dataForDefaultMetrics && dataForDefaultMetrics?.Metrics?.find((e) => e.label === MetricsLabel)?.submetrics?.find((e) => e.label === subquerykey)?.value
  //   let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);
  //   const payload = {
  //     // StartDate: moment(last60Days).format(),
  //     StartDate: dataForDefaultMetrics && dataForDefaultMetrics?.Duration?.StartDate,
  //     EndDate: dataForDefaultMetrics && dataForDefaultMetrics?.Duration?.EndDate,
  //     QueryKey: subquerykey,
  //     StartingRow: 1,
  //     RowsCount: 10,
  //     Channel:
  //       selectedStore?.toUpperCase() === "STORE" ? "IN-STORE" : selectedStore,
  //     // Duration: resources.LAST_60_DAYS_VALUE,
  //     Duration: dataForDefaultMetrics && dataForDefaultMetrics?.Duration?.Duration,
  //     TotalCount: Math.round(Number(NintyDaysCount))?.toString(),
  //     MetricsLabel: MetricsLabel,
  //     Client: getClientName?.Client,
  //   };
  //   setLoading(true);
  //   API[ActionTypes.GET_SUBMATRICS_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
  //     .then(({ data }) => {
  //       setLoading(false);
  //       if (data) {
  //         // setRefreshedOn(moment().format("YYYY-MM-DD HH:mm:ss"));
  //         openPopup(true);
  //         setClassInOut("modal-container modal-space-in");
  //         dispatch({
  //           type: ActionTypes.GET_SUBMATRICS_DETAILS_SUCCESS,
  //           payload: data?.GetSubMetricsDetailsResponse,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       openFailurePopup(true);
  //       setClassInOut("modal-container modal-space-in");
  //       dispatch({
  //         type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
  //         payload: err,
  //       });
  //       // dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
  //       let _payload = {
  //         level: resources.POST_LOGGER_ERROR,
  //         category: resources.POST_LOGGER_API,
  //         ApiName: resources.POST_LOGGER_APINAME,
  //         message: {
  //           error: err,
  //           info: payload,
  //         },
  //       };
  //       API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);

  //       let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);
  //         let last60Days = moment().subtract(60, 'days').calendar()

  //         const payloadEmail = {
  //           // StartDate: moment(last60Days).format(),
  //           StartDate: projectData?.Duration?.StartDate,
  //           EndDate: projectData?.Duration?.EndDate,
  //           QueryKey: subquerykey,
  //           Channel:
  //             selectedStore?.toUpperCase() === "STORE" ? "IN-STORE" : selectedStore,
  //           Email: userDetails?.email,
  //           TotalCount: value?.toString(),
  //           MetricsLabel: MetricsLabel,
  //           Client: getClientName?.Client,
  //           // Duration: resources.LAST_60_DAYS_VALUE
  //           Duration: projectData?.Duration?.Duration,
  //           isFailureScenario: true,
  //           isExport: true
  //         };
  //         setLoading(true);
  //         API[ActionTypes.GET_SUBMATRICS_DETAILS](payloadEmail, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
  //           .then(({ data }) => {
  //             setLoading(false);
  //             if (data) {
  //               // if (value <= 1000) {
  //               //   exportToCSVInventory(
  //               //     tableColumns,
  //               //     data?.GetSubMetricsDetailsResponse,
  //               //     "",
  //               //     "",
  //               //     label
  //               //   );
  //               //   // setRefreshedOn(moment().format("YYYY-MM-DD HH:mm:ss"));
  //               //   dispatch({
  //               //     type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_SUCCESS,
  //               //     payload: data?.GetSubMetricsDetailsResponse,
  //               //   });
  //               // } else {
  //                 dispatch({
  //                   type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_SUCCESS,
  //                   payload: data,
  //                 });
  //               // }
  //             }
  //           })
  //           .catch((err) => {
  //             setLoading(false);
  //             dispatch({
  //               type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
  //               payload: err,
  //             });
  //             dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
  //             let _payload = {
  //               level: resources.POST_LOGGER_ERROR,
  //               category: resources.POST_LOGGER_API,
  //               ApiName: resources.POST_LOGGER_APINAME,
  //               message: {
  //                 error: err,
  //                 info: payloadEmail,
  //               },
  //             };
              // API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
  //           });
  //     });
  //   // .finally(() => {
  //   //   setLoading(false);
  //   // });
  // };

    const getDetails = async (evt) =>{
      dispatch({
        type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS,
        payload: [],
      });

      let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);

      const payload = {
        DashboardId:
          selectedStore?.toLowerCase() == "care" ? "all" : selectedDashboard,
        DashboardName: selectedTab,
        Channel: selectedStore?.toLowerCase() == "care" ? "all" : selectedStore,
        ClientId: selectedClient,
        Client: getClientName?.Client,
        Duration: selectedDate == resources.LAST_30_DAYS_VALUE || selectedDate == resources.LAST_60_DAYS_VALUE ? selectedDate : resources.LAST_90_DAYS_VALUE,
      };
      setLoading(true);
      dispatch({
        type: ActionTypes.SET_DASHBOARD_LOADING,
        payload: true,
      });
      API[ActionTypes.GET_DASHBOARD_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
        .then(({ data }) => {
          if (data) {
            setLoading(true);
            dispatch({
              type: ActionTypes.SET_DASHBOARD_LOADING,
              payload: false,
            });
            // dispatch({
            //   type: ActionTypes.SET_REFRESHED_ON,
            //   payload: moment().format("YYYY-MM-DD HH:mm:ss"),
            // });
            dispatch({
              type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS_90_DAYS,
              payload: data?.GetDashboardResponse,
            });
            dispatch({
              type: ActionTypes.GET_DASHBOARD_DETAILS_DATA_SUCCESS,
              payload: true,
            });
            setCursorPositioning(evt)
            const NintyDaysCount = data?.GetDashboardResponse && data?.GetDashboardResponse?.Metrics?.find((e) => e.label === MetricsLabel)?.submetrics?.find((e) => e.label === subquerykey)?.value
            let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);
            const payload = {
              StartDate: data?.GetDashboardResponse && data?.GetDashboardResponse?.Duration?.StartDate,
              EndDate: data?.GetDashboardResponse && data?.GetDashboardResponse?.Duration?.EndDate,
              QueryKey: subquerykey,
              StartingRow: 1,
              RowsCount: 10,
              Channel:
                selectedStore?.toUpperCase() === "STORE" ? "IN-STORE" : selectedStore,
              Duration: data?.GetDashboardResponse && data?.GetDashboardResponse?.Duration?.Duration,
              TotalCount: Math.round(Number(NintyDaysCount))?.toString(),
              MetricsLabel: MetricsLabel,
              Client: getClientName?.Client,
            };
            API[ActionTypes.GET_SUBMATRICS_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
              .then(({ data }) => {
                setLoading(false);
                if (data) {
                  openPopup(true);
                  setClassInOut("modal-container modal-space-in");
                  dispatch({
                    type: ActionTypes.GET_SUBMATRICS_DETAILS_SUCCESS,
                    payload: data?.GetSubMetricsDetailsResponse,
                  });
                }
              })
              .catch((err) => {
                setLoading(false);
                openFailurePopup(true);
                setClassInOut("modal-container modal-space-in");
                dispatch({
                  type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
                  payload: err,
                });
                // dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
                let _payload = {
                  level: resources.POST_LOGGER_ERROR,
                  category: resources.POST_LOGGER_API,
                  ApiName: resources.POST_LOGGER_APINAME,
                  message: {
                    error: err,
                    info: payload,
                  },
                };
                API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);

                let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);
                  let last60Days = moment().subtract(60, 'days').calendar()

                  const payloadEmail = {
                    StartDate: projectData?.Duration?.StartDate,
                    EndDate: projectData?.Duration?.EndDate,
                    QueryKey: subquerykey,
                    Channel:
                      selectedStore?.toUpperCase() === "STORE" ? "IN-STORE" : selectedStore,
                    Email: userDetails?.email,
                    TotalCount: value?.toString(),
                    MetricsLabel: MetricsLabel,
                    Client: getClientName?.Client,
                    Duration: projectData?.Duration?.Duration,
                    isFailureScenario: true,
                    isExport: true
                  };
                  setLoading(true);
                  API[ActionTypes.GET_SUBMATRICS_DETAILS](payloadEmail, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
                    .then(({ data }) => {
                      setLoading(false);
                      if (data) {
                          dispatch({
                            type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_SUCCESS,
                            payload: data,
                          });
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                      dispatch({
                        type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
                        payload: err,
                      });
                      dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
                      let _payload = {
                        level: resources.POST_LOGGER_ERROR,
                        category: resources.POST_LOGGER_API,
                        ApiName: resources.POST_LOGGER_APINAME,
                        message: {
                          error: err,
                          info: payloadEmail,
                        },
                      };
                      API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
                    });
              });
          }
        })
        .catch((err) => {
          setLoading(false);
          dispatch({
            type: ActionTypes.SET_DASHBOARD_LOADING,
            payload: false,
          });
          dispatch({
            type: ActionTypes.GET_DASHBOARD_DETAILS_FAILURE,
            payload: err,
          });
          // dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
          dispatch({
            type: ActionTypes.GET_DASHBOARD_DETAILS_DATA_SUCCESS,
            payload: true,
          });
          let _payload = {
            level: resources.POST_LOGGER_ERROR,
            category: resources.POST_LOGGER_API,
            ApiName: resources.POST_LOGGER_APINAME,
            message: {
              error: err,
              info: payload,
            },
          };
          API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
          dispatch({
            type: ActionTypes.SET_REFRESHED_ON,
            payload: moment().format("YYYY-MM-DD HH:mm:ss"),
          });
        })
        .finally(() => {
          setLoading(true);
        });
      }

  //stop animation after 5seconds
  setTimeout(() => {
    setStopAnimation(true);
  }, [5000]);

  useEffect(() => {
    if (value) {
      setStopAnimation(false)      // setanimateNumber(true);
    }
  }, [value]);

  const openPopup1 = () => {
    setTimeout(() => {
      openPopup(false);
      dispatch({
        type: ActionTypes.GET_SUBMATRICS_DETAILS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_SUCCESS,
        payload: undefined,
      });
      dispatch({
        type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS,
        payload: [],
      });
    }, [2000]);
  };

  const openPopup2 = () => {
    setTimeout(() => {
      openFailurePopup(false);
      dispatch({
        type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
        payload: [],
      });
      dispatch({
        type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_FAILURE,
        payload: undefined,
      });
    }, [2000]);
  };

  const getReportDetails = () => {
    dispatch({
      type: ActionTypes.GET_SUBMATRICS_DETAILS_SUCCESS,
      payload: [],
    });
    let payload = {
      StartDate: reportFilterDuration?.startDate,
      EndDate: reportFilterDuration?.endDate,
      ReportType: reportType,//barLabel.toLowerCase() === 'servicerequest' ? 'servicerequest' : 'enrollment',
      Type: reportType.toLowerCase() === 'servicerequest' ? undefined : label ,
      // Action: selectedStarhubReport?.starHubReportValue === 'servicerequest' ? undefined : action,
      StatusCode: reportType.toLowerCase() === 'servicerequest' ? label : undefined,
      // Reason: selectedStarhubReport?.starHubReportValue === 'servicerequest' ? undefined : action
  }
  setLoading(true)
  API[ActionTypes.GET_STARHUB_REPORT_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
  .then(({ data }) => {
      setLoading(false);
      if (data) {
        openPopup(true);
        setClassInOut("modal-container modal-space-in");
        dispatch({
          type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      openPopup(false);
      dispatch({
        type: ActionTypes.GET_STARHUB_REPORT_DETAILS_FAILURE,
        payload: err,
      });
      dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
      let _payload = {
        level: resources.POST_LOGGER_ERROR,
        category: resources.POST_LOGGER_API,
        ApiName: resources.POST_LOGGER_APINAME,
        message: {
          error: err,
          info: {
            idToken: "",
          },
        },
      };
      API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
    });
  }

  let infoData = [{
    label: 'TotalCount',
    reportType: 'enrollment',
    info: 'Total volume subscription requests'
  },
  {
    label: 'AcceptedCount',
    reportType: 'enrollment',
    info: 'Total volume Accepted subscription'
  },
  {
    label: 'RejectedCount',
    reportType: 'enrollment',
    info: 'Total volume Rejected subscription'
  },
  {
    label: 'Total_Count',
    reportType: 'servicerequest',
    info: 'Total volume of Service Requests'
  },
  {
    label: 'Cancelled',
    reportType: 'servicerequest',
    info: 'Total volume of cancelled Service Requests'
  },
  {
    label: 'Case completed successfully',
    reportType: 'servicerequest',
    info: 'Total volume of completed Service Requests'
  },
  {
    label: 'Case In Progress',
    reportType: 'servicerequest',
    info: 'Total volume of in progress Service Requests'
  }
]

const [openTooltip, setOpenTooltip] = useState(false)
const [tooltipText, setTooltip] = useState(null)


const   openInfoPopup = (e, label, reportType) => {
  e.stopPropagation()
  setOpenTooltip(true)
  setTooltip(infoData?.filter(v => v.reportType?.toLowerCase() === reportType?.toLowerCase() && v.label?.toLowerCase() === label?.toLowerCase())[0]?.info)
}

  return (
    <>
      <div
      id={id}
      key={label}
      className={`${className} ${status?.toLowerCase()}-cards ${metricsCss} rounded-xl shadow-card text-black p-2 ${bgColor} border-3px my-3  
      ${!openTooltip ? 'transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500' : '' } perf-card
      ${metricsCss ? 'ml-0' : 'ml-3'}
      ${value == 'NA' ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      // onClick={(evt) => value == 'NA' ? undefined : config.isSGP === 'true' ? getReportDetails() : isSubMatrics ? getSubData(evt, subquerykey, MetricsLabel, value, label, colors) : getDetails(evt)}
      onClick={(evt) => value == 'NA' ? undefined : isSubMatrics ? getSubData(evt, subquerykey, MetricsLabel, value, label, colors) : getDetails(evt)}
    >
      
        <div className="flex flex-row justify-between w-full" id="label" >
          <div className={`${config.isSGP === 'true' ? '' : '' } text-sm bg-inherit text-app-border w-[90%]`} id="label1">{label}</div>
          <>
          {config.isSGP === 'false' ?
            <>
              {status?.toLowerCase() === "green" ? (
                <GreenDashboardCircle className={`w-5 h-5 animate-pulse-green`} />
              ) : status?.toLowerCase() === "amber" ||
                status?.toLowerCase() === "yellow" ? (
                <YellowDashboardCircle className={`w-5 h-5 animate-pulse-yellow`} />
              ) : (
                <RedDashboardCircle className={`w-5 h-5 animate-pulse-red`} />
              )}
            </>
            :
            <>
              <a
                className="cursor-pointer float-right w-auto text-xs ml-5 mt-1"
                onClick={(e) => openInfoPopup(e, label, reportType)}
              >
                <InfoIcon className="w-3 h-3" fill="green" />
                <div
                  className={`flex flex-row justify-between items-center absolute md:left-0 right-0 md:w-auto mx-auto w-300 ${openTooltip ? 'visible' : 'hidden'
                    }  z-10 md:mx-5 py-2 px-4 flex flex-row text-14 rounded-md border text-blue-700 shadow-lg border-blue-700`}
                  style={{ backgroundColor: '#F0F3FF' }}
                >
                  {tooltipText}
                  <CrossIcon onClick={(e) => (e.stopPropagation(), setOpenTooltip(false), setTooltip(null))} />
                </div>
              </a>
            </>
          }
          </>
        </div>
      <div
        id="value"
        className="text-2xl mt-5 font-normal text-black flex flex-row justify-between items-center px-1 h-8"
      >
        {/* {(label?.includes("$") || label?.toLowerCase() === 'amount to be invoiced' || label?.toLowerCase() === 'amount invoiced') ? "$" : ""} */}
        <div className="flex">
          <p className={`${config.isSGP === 'true' ? '' : '' } mr-[2px]`}>{getTypeIndicator(subMetricsTypeIndicator, mapSelected)}</p>
          {dashboardLoading ? 
            <LoadingNumbers className="w-10 h-10" fill={status?.toLowerCase()} />
            :
            stopAnimation ? (
              <div> 
                {(selectedTab === "StarHub BPM Performance" || selectedTab === "StarHub Operational Performance" || selectedTab === 'Singtel BPM Performance' || selectedTab === 'M1 BPM Performance' || selectedTab === 'AIS BPM Performance' || selectedTab === 'TRUE BPM Performance' || selectedTab === 'Asurion_TechCare BPM Performance' || selectedTab === 'Celcom BPM Performance' || selectedTab === "FET BPM Performance") ? 
                <p> {numberWithCommas(Math.round(Number(value)))} </p>
              :
              <p>
                {value == 'NA' ? <p className="">NA</p> : numberWithCommas(Math.round(Number(value)))}
              </p>
              }
              </div>
            ) : (
              <>
              <div> {(selectedTab === "StarHub BPM Performance" || selectedTab === 'Singtel BPM Performance' || selectedTab === 'M1 BPM Performance' || selectedTab === 'AIS BPM Performance' || selectedTab === 'TRUE BPM Performance' || selectedTab === 'Asurion_TechCare BPM Performance' || selectedTab === 'Celcom BPM Performance' || selectedTab === "FET BPM Performance") ?
                <>{
                <>
                <AnimatedNumbers
                includeComma
                animateToNumber={Math.round(Number(value))}
                // fontStyle={{ fontSize: 24 }}
                configs={(number, index) => {
                  return {
                    mass: number,
                    tension: 230 * (index + 1),
                    friction: 140,
                  };
                }}
                ></AnimatedNumbers>
              </>
              }</>
              : 
              <>
              {value == 'NA' ? 
                <p className="">NA</p>
                :
                <>
                <AnimatedNumbers
                includeComma
                animateToNumber={Math.round(Number(value))}
                // fontStyle={{ fontSize: 24 }}
                configs={(number, index) => {
                  return {
                    mass: number,
                    tension: 230 * (index + 1),
                    friction: 140,
                  };
                }}
                ></AnimatedNumbers>
              </>
              }
              </>}
              </div>
              </>
            )
          }
        </div>
        {showMinusIcon ? 
          <span className="p-2px transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-app-purple hover:rounded-sm" onClick={(e)=> (e.stopPropagation(), collapseMatrics('collapse',submetrics))}>
              <MinusSignIcon className="" fill="#8223D2" /> 
          </span>
          :
          <>
          {(label === submetrics?.label && removedSubMatricsData?.filter(r => r?.label === label)?.[0]?.submetrics?.length > 0) &&
            <span className="p-2px transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-app-purple hover:rounded-sm" onClick={(e)=> (e.stopPropagation(), collapseMatrics('expand',submetrics))}>
              <PlusSignIcon className="" fill="#8223D2" /> 
            </span>
          }
          </>
        }
      </div>
    </div>
      
      {config.isSGP === 'false' ?
        <Popup
          show={isOpen}
          global={true}
          className={`rounded-xl ${classInOut}`}
          fitContent={true}
          // onClose={() => openPopup(false)}
          submatricsDetailsData={submatricsDetailsData}
          submatrics={true}
        >
          {(isOpen && (submatricsDetailsData)) && (
            <DataTable
              submatricsDetailsData={submatricsDetailsData}
              selectedDate={selectedDate}
              subquerykey={subquerykey}
              label={label}
              value={value}
              colors={colors}
              MetricsLabel={MetricsLabel}
              onClose={(e) => ( openPopup1(), setClassInOut("modal-container modal-space-out"), e.stopPropagation()
              )}
              resources={resources}
            />
          )}
        </Popup>
        :
        <Popup
          show={isOpen}
          global={true}
          className={`rounded-xl ${classInOut}`}
          fitContent={true}
          // onClose={() => openPopup(false)}
          submatricsDetailsData={reportDetailsSuccess || submatricsDetailsData}
          submatrics={true}
        >
          {(isOpen && (reportDetailsSuccess || submatricsDetailsData)) && (
            <SGPDataTable
              reportDetailsSuccess={reportDetailsSuccess || submatricsDetailsData}
              selectedDate={selectedDate}
              subquerykey={subquerykey}
              label={label}
              value={value}
              colors={colors}
              MetricsLabel={MetricsLabel}
              onClose={(e) => ( openPopup1(), setClassInOut("modal-container modal-space-out"), e.stopPropagation()
              )}
              resources={resources}
            />
          )}
        </Popup>
      }

      <Popup
        show={isfailurePopupOpen}
        global={true}
        className={`rounded-xl ${classInOut}`}
        fitContent={true}
        submatrics={true}
      >
        {isfailurePopupOpen && (
          <div className="flex flex-row gap-3 justify-between items-center h-20 px-4 py-4" onClose = {(e) => (openPopup2(), setClassInOut("modal-container modal-space-out"), e.stopPropagation())}>
            <p className="text-black text-base font-bold">{label}</p>
            <p className="text-black text-sm mt-4 mb-4">It is taking more time than expected to fetch the data. You will receive email with details shortly.!</p>
            <CrossIcon className="w-4 h-4 cursor-pointer" onClick={() => openFailurePopup(false)}/>
          </div>
        )}
      </Popup>
    </>
  );
};

PerformanceCard.defaultProps = {
  color: "",
  label: "",
  value: 0,
};

export default PerformanceCard;
