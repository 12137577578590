import React, { useEffect, useRef } from "react";
import {
  Routes,
  //   MemoryRouter as Router,
  BrowserRouter as Router,
  Route,
  Navigate,
  useLocation,
  MemoryRouter,
} from "react-router-dom";
import Application from "./pages/Application";
import Login from "./pages/login/login";
import { useCentralStore } from "./store/appContext";
import { AuthConsumer } from "./utils/authProvider";
import { isEmpty } from "./utils/functionUtils";
import ActionTypes from "./store/actionTypes";
import config from "./config/config";
import ErrorBoundary from "./ErrorBoundary";
import LoginForm from "./pages/login/LoginForm";
import OtpPage from "./pages/login/otp";

const ConsumedComponent = ({ Component }) => {
  return (
    <AuthConsumer>
      {({ signinRedirect, generateToken, signoutRedirectCallback }) => {
        return (
          <Component
            signinRedirect={signinRedirect}
            generateToken={generateToken}
            signoutRedirectCallback={signoutRedirectCallback}
          />
        );
      }}
    </AuthConsumer>
  );
};

const PrivateRoute = ({ Component }) => {
  const [{ userLoggedIn }, dispatch] = useCentralStore();
  let auth = useRef(userLoggedIn);

  const location = useLocation();

  useEffect(() => {
    if (userLoggedIn && !auth.current) {
      auth.current = true;
    }
  }, [userLoggedIn]);

  useEffect(() => {
    if (
      !isEmpty(location.search) &&
      location.search.startsWith("?code=") &&
      location.search.includes("&state=")
    ) {
      dispatch({
        type: ActionTypes.SET_AUTH_CODE_SEARCH,
        payload: location.search,
      });
    }
  }, [location]);

  //avoid back forth navigation using browers buttons
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.history.back();
    window.onpopstate = () => window.history.forward();
  }, []);

  // Remove code " || !config.isLocal " once we have SSO client Ids created for all envs
  return auth.current || !config.isLocal ? (
    // return auth.current ? (
    <AuthConsumer>
      {({ signinRedirect, generateToken, signoutRedirectCallback }) => {
        return (
          <Component
            signinRedirect={signinRedirect}
            generateToken={generateToken}
            signoutRedirectCallback={signoutRedirectCallback}
          />
        );
      }}
    </AuthConsumer>
  ) : (
    <Navigate to="/login" />
  );
};

const AllRoutes = () => {
  const [{ isApiError, userData, userDetails }] = useCentralStore();
  return (
    <ErrorBoundary isApiError={isApiError} userData={userData} userDetails={userDetails}>
      <MemoryRouter>
        <Routes>
          <Route
            path="/login"
             element={<ConsumedComponent Component={config.isSGP === 'true' ? LoginForm : Login} />}
            // element={<ConsumedComponent Component={Login} />}
          />
          <Route
            path="/otpPage"
             element={<ConsumedComponent Component={OtpPage} />}
            // element={<ConsumedComponent Component={Login} />}
          />
          <Route path="/" element={<PrivateRoute Component={Application} />} />
        </Routes>
      </MemoryRouter>
    </ErrorBoundary>
  );
};

export default AllRoutes;