import React from "react"
import ClientArrowDiv from '../../assets/icons/clientsArrowDiv'
import DashboardArrow from '../../assets/icons/dashboardArrow'
import ProgramCards from "./ProgramCards"

const ClientsInfo = ({ clientObject, hideLastArrow, dispatch, actionTypes, programData, resources }) => {

  // For Future use, to add Enabled flag in programs for clients  
  // for(const t in programData){
  //   programData[t].map(v=>{
  //       if(t.toUpperCase() === 'STARHUB'){
  //           v.enabled =true
  //       }
  //       else v.enabled =false
  //   })
  //   console.log(t,programData[t])
  // }
  
    return(        
    <>
    {clientObject && 
      <div className="relative">
        <div className="p-2 mx-5 w-3/4">
          <div className="relative">
            <p
              className={`absolute text-xs font-bold text-center`}
              style={{
                top: "50%",
                right: "55%",
                transform: "translate(50%, -50%)"
              }}
            >
              {clientObject?.toUpperCase()?.replaceAll('_', ' ')}
            </p>
            <ClientArrowDiv className="" />
            {!hideLastArrow && (
              <DashboardArrow
                className="w-8 h-8 absolute top-15% -right-35%"
                fill="#A839F3"
              />
            )}
          </div>
            <ProgramCards 
              clientName={clientObject}
              programData={programData[clientObject]}
              dispatch={dispatch} 
              actionTypes={actionTypes} 
              resources={resources}
            />
        </div>
      </div>
      }
    </>
  )
}

export default ClientsInfo