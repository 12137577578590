import { useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Form, Input } from 'antd';
import { useCentralStore } from "../../store/appContext";
import ActionTypes from "../../store/actionTypes";
import { useNavigate } from "react-router-dom";
import { getParameterByName, isEmpty } from "../../utils/functionUtils";
import API from "../../api";
import OtpPage from "./otp";
import FloatLabelInputField from "./FloatLabelInputFeild";
import Spinner from "../../components/common/spinner/spinner";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const LoginForm = ({}) => {
  const [{ userLoggedIn, authCodeSearch, email, password, loadingData}, dispatch] = useCentralStore();
 const  [loginerror,setLoginerror] =useState()
 const  [emailerror,setEmailerror] =useState()
 const  [passworderror,setPasswordError] =useState()
  const navigate = useNavigate();

  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
  };

  const validateEmail = (values) => {
    const errors = {}
  
    if (!values) {
      setEmailerror('Email Address Required')
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values)) {
      setEmailerror('Invalid Email address')
    }else{
      setEmailerror('')
    }
    return errors
  }
  const validatePassword = (values) => {
    const errors = {}
  
    if (!values) {
      setPasswordError('Password is Required')
    }else{
      setPasswordError('')
    }
    return errors
  }

  // const onFinish = (values) => {
      
  //   };
  //   const onFinishFailed = (errorInfo) => {
  //     console.log('Failed:', errorInfo);
  // };

  // const navigateToOTPPage = useCallback(() => {
  //   navigate("/otpPage");
  // }, [navigate]);

  const handleClick = ()=>{
    window.dataLayer.push({
      'event': 'OneOps_SEA_Console_DL_Event Login',
      'Partner': 'OneOps',
      'Type': 'SEA',
      'EmailAddress': email
    });
    navigate("/otpPage");
  }

  const Email = (e) => {
    dispatch({
      type: ActionTypes.SET_EMAIL_ADDRESS,
      payload: e.target.value,
    });
    validateEmail(e.target.value)
  };
  const Password  = (e) => {
    dispatch({
      type: ActionTypes.SET_PASSWORD,
      payload: e.target.value,
    });
    validatePassword(e.target.value)
  };
 const logindisable = () => {
  return(
  (email == "")  || (password == "")
  )
 }

  useEffect(() => {
    if (userLoggedIn) {
      OtpPage();
    }
  }, [userLoggedIn]);

  
   const login = () => {
    setLoading(true)
    //  API[ActionTypes.OTP_REQUEST]("mrunal.deshmukh@asurion.com", "Madhavi@123")
     API[ActionTypes.OTP_REQUEST](email, password)
          .then((data) => {
            setLoading(false);
           dispatch({
             type: ActionTypes.SET_USER_LOGGED_IN,
             payload: true,
           });
           handleClick()
           dispatch({
            type: ActionTypes.SET_USER_DATA,
            payload: {access_token: data?.signInUserSession?.accessToken?.jwtToken}
          });
          dispatch({
            type: ActionTypes.SET_USER_DETAILS,
            payload: 'guest.user@mailinator.com' //data?.attributes?.email
          });
          
         })
         .catch((err) => {
          setLoading(false)
          if(err){
            setLoginerror('Invalid Username or Password')
          }
           console.log(`error is ${err}`);
         });

   }

  return (<>
   {loadingData && <Spinner showOverlay={true} />}
    <div className="bg-white h-screen text-black">
      <div className="background-container">
      <div className="boxone"></div>
      <div className=" box   "></div>
      <div className="flex justify-evenly">
        <div className="content">
        <img className="mx-auto mt-28 h-32 w-32" src={require('../../assets/Starhub/otpStar.png')}/>
          <div className={``}>
            <div className={`Logintitle text-center`}>
              <p className={`texts`}>
                Sign in and start managing your
                <p className={`textfonts`}>Device Protection Program</p>
              </p>
           </div>
            <br></br>
                <div class="relative">
    <input 
    type="text" 
    id="floating_outlined"
    class="block  px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black-300 appearance-none dark:text-white dark:border-black-600 dark:focus:border-black-500 focus:outline-none focus:ring-0 focus:border-black-600 peer" 
    placeholder="Email Address"  
    defaultValue={email}
     onChange={Email}
     />
    <label for="floating_outlined" class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 color:gray">Email Address</label>
</div>
<br/>
<div class="relative">
    <input 
    type="password" 
    id="floating_outlined"
    class="block  px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black-300 appearance-none dark:text-white dark:border-black-600 dark:focus:border-black-500 focus:outline-none focus:ring-0 focus:border-black-600 peer" 
    placeholder="Password"  
    defaultValue={password}
     onChange={Password}
     
     />
    <label for="floating_outlined" class="
    absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 text-gray-100">Password</label>
</div>
<div className="mt-5 mx-m15 flex justify-center">
                    <button
                        id="continue"
                        onClick={login}
                        className="px-4 py-2 bg-app-lightGreen w-full rounded-lg"
                        type="submit"
                        // disabled={logindisable}
                        >
                        Login
                    </button>
                </div>
                {loginerror && <p className="error">{loginerror}</p>}
                {emailerror && !loginerror && <p className="error">{emailerror}</p>} 
                {passworderror && !emailerror && !loginerror&& <p className="error">{passworderror}</p>}
          </div>
        </div>
        <div className="flex justify-end">
        <LazyLoadImage
        className="h-screen"
    // alt={image.alt}
    loading="lazy" 
    src={require('../../assets/icons/LaptopLogin.png')} />
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default LoginForm;
