import React, { useState } from "react";
import * as xlsx from "xlsx";
import moment from "moment";
import ActionTypes from "../../store/actionTypes";
import { useCentralStore } from "../../store/appContext";
import { bytesToSize, generateUniqueId, getBase64 } from "../../utils/upload-utils";
import Selector from "../../components/common/selector/selector";
import { useEffect } from "react";
// import { isEmpty } from "../../utils/functionUtils";
// import CustomButton from "../../../src/components/common/button/button";

const UploadFile = React.forwardRef(({setSuccessMsg},ref) => {
  const [{selectedFileType, chooseFile, selectedRevenueMonth, selectedRevenueYear, selectedRevenueClient, selectedAccountingMonth, uploadFileDataClick }, dispatch] =
    useCentralStore();

    const [client, setClient] = useState("581D29C51B9511E8B77B0ADC30863E72")
    const [selectMonth, setSelectMonth] = useState(selectedAccountingMonth.split("-")[0])
    const [selectYear, setSelectYear] = useState(parseInt(`20${selectedAccountingMonth.split("-")[1]}`))
    const [selectFileType, setSelectFileType] = useState(uploadFileDataClick?.fileType)


  const months = [
    { name: "Jan", value: "Jan" },
    { name: "Feb", value: "Feb" },
    { name: "Mar", value: "Mar" },
    { name: "Apr", value: "Apr" },
    { name: "May", value: "May" },
    { name: "Jun", value: "Jun" },
    { name: "Jul", value: "Jul" },
    { name: "Aug", value: "Aug" },
    { name: "Sep", value: "Sep" },
    { name: "Oct", value: "Oct" },
    { name: "Nov", value: "Nov" },
    { name: "Dec", value: "Dec" },
  ];

  const currentyear = new Date().getFullYear();
  const years = Array.from(new Array(20), (val, index) => currentyear - index);
  const yearsList = [];
  years.forEach((year, i) => {
    let obj = { name: year, value: year };
    yearsList.push(obj);
  });

  const handleChange = (name, value, placeholder) => {
    switch (placeholder) {
      case 'selectedClient' :
        setClient(value)
        dispatch({ type: ActionTypes.SELECTED_REVENUE_CLIENT, payload: value });
        dispatch({
          type: ActionTypes.SELECTED_FILE,
          payload: null
        });
        ref.current.value = ""
          break;
      case 'selectedMonth' :
        setSelectMonth(value)
        dispatch({ type: ActionTypes.SELECTED_REVENUE_MONTH, payload: value });
        dispatch({
          type: ActionTypes.SELECTED_FILE,
          payload: null
        });
        ref.current.value = ""
          break;
      case 'selectedYear' :
        setSelectYear(value)
        dispatch({ type: ActionTypes.SELECTED_REVENUE_YEAR, payload: value });
        dispatch({
          type: ActionTypes.SELECTED_FILE,
          payload: null
        });
        ref.current.value = ""
          break;
      case 'selectedFileType' :
        setSelectFileType(value);
        dispatch({ type: ActionTypes.SELECTED_FILE_TYPE, payload: value });
        dispatch({
          type: ActionTypes.SELECTED_FILE,
          payload: null
        });
        ref.current.value = ""
          break;
      default:
        return;
  }
  }


  const validFileExtension = (fileExtension) => {
    // const validFileExtension = ["xls", "xlsx"];

    const invalidFileExtension = [
      "bat",
      "exe",
      "pdf",
      "jpg",
      "jpeg",
      "txt",
      "doc",
      "docs",
      "docx",
      "xlsx",
      "xls"
    ];
    if (invalidFileExtension.includes(fileExtension)) {
      dispatch({
        type: ActionTypes.UPLOAD_FILE_ERROR,
        payload: `Invalid file type ${fileExtension}`,
      });
      return false;
    }
    return true;
  };

  const getClientName = (client) => {
    if (client === "581D29C51B9511E8B77B0ADC30863E72") {
      return "M1";
    } else if (client === "6E8BB98D48FD11EB94E90AA01D6C824E") {
      return "Australia_Digital";
    }
    return client
  };

  useEffect(() => {
    uploadFileDataClick && dispatch({ type: ActionTypes.SELECTED_REVENUE_CLIENT, payload:"581D29C51B9511E8B77B0ADC30863E72" });
    dispatch({ type: ActionTypes.SELECTED_REVENUE_MONTH, payload: selectedAccountingMonth.split("-")[0] });
    dispatch({ type: ActionTypes.SELECTED_REVENUE_YEAR, payload: parseInt(`20${selectedAccountingMonth.split("-")[1]}`) });
    dispatch({ type: ActionTypes.SELECTED_FILE_TYPE, payload: uploadFileDataClick?.fileType })
  },[])

  const readFileAsDataURL = async (file) => {
    let fileSize = bytesToSize(file.size);
    let base64 = await getBase64(file);
    const extension = file.name.split(".")[1];
    const reader = new FileReader();
    let timestamp = moment().format()?.split("+")[0];
    reader.readAsArrayBuffer(file);
    reader.onload = (upload) => {
      if (validFileExtension(extension)) {
        const ProcessingMonthYear = selectMonth.concat('_', selectYear);
        const fileType = selectFileType.toUpperCase();
        let uuid = generateUniqueId();
        let uniqueFileName = fileType
          .concat("_", getClientName(client))
          .concat("_", ProcessingMonthYear)
          .concat("_", timestamp)
          .concat(".", extension);
        const bufferArray = upload.target.result;
        const workbook = xlsx.read(bufferArray, { type: "buffer" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        const record = JSON.stringify(json.length);

        dispatch({
          type: ActionTypes.SELECTED_FILE,
          payload: {
            fileId: uuid,
            name: uniqueFileName,
            fileType: file.type,
            extension: extension,
            timestamp: timestamp,
            preview: file.preview,
            fileSize: fileSize,
            data: upload.target.result,
            record: record,
            base64: base64,
          },
        });
      }
    };
    let newReader = new FileReader();
    newReader.readAsDataURL(file);
  };

  const clickChooseFile = (e) => {
    dispatch({
      type: ActionTypes.SELECTED_FILE,
      payload: null
    });
    
    dispatch({
      type: ActionTypes.UPLOAD_FILE_ERROR,
      payload: undefined,
    });
    
    readFileAsDataURL(e.target.files[0]);
    // dispatch({ type: ActionTypes.UPLOAD_FILE_ERROR, payload: null })
  };
  return (
    <>
      <div className="flex">
        <Selector
          key="selectedClient"
          options={[
            { name: "M1", value: "581D29C51B9511E8B77B0ADC30863E72" },
            { name: "Australia_Digital", value: "6E8BB98D48FD11EB94E90AA01D6C824E" },
          ]}
          label="Select Client"
          value={selectedRevenueClient}
          onSelect={(name, val) => handleChange(name, val, "selectedClient")}
          // placeholder="Client"
        />
        <Selector
          key="selectedMonth"
          options= {months}
          label="Select Month"
          value={selectedRevenueMonth}
          onSelect={(name, val) => handleChange(name, val, "selectedMonth")}
          // placeholder="Month"
        />
        <Selector
          key="selectedYear"
          options={yearsList}
          label="Select Year"
          value={selectedRevenueYear}
          onSelect={(name, val) => handleChange(name, val, "selectedYear")}
          // placeholder="Year"
        />
        <Selector
          key="selectedFileType"
          options={[
            { name: "Billing Data", value: "Billing" },
            { name: "Clawback Data", value: "Clawback" },
          ]}
          label="File Type &nbsp;"
          value={selectedFileType}
          onSelect={(name, val) => handleChange(name, val, "selectedFileType")}
          // placeholder="Select Type"
        />
        <div className="relative mr-3 w-fit">
          <div className="flex px-1 py-1.5 border rounded-lg border-app-border last:mr-0">
            <div className="flex cursor-pointer">
              <div className="pr-1 font-bold bg-transparent outline-none">
                <input
                  label="Choose File"
                  name="chooseFile"
                  ref={ref}
                  type="file"
                  className="w-[260px]"
                  onClick={(e) => (setSuccessMsg(false))}
                  onChange={(e) => {
                    clickChooseFile(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default UploadFile;
