import Dollar from '../assets/icons/dollor'
import TradeInExecutionIcon from '../assets/icons/tradeInExecutionIcon'
import ReturnsIcon from '../assets/icons/returnsIcon'
import MonetizeDeviceIcon from "../assets/icons/monetizeDeviceIcon";
import DeviceRecieptIcon from "../assets/icons/deviceRecieptIcon";
import SettlementIcon from "../assets/icons/settlementIcon";
import CutomerInquiriesIcon from "../assets/icons/cutomerInquiriesIcon";
import EnrollmentIcon from "../assets/icons/enrollmentIcon";
import DeviceRegIcon from "../assets/icons/deviceRegIcon";
import ClaimsIcon from "../assets/icons/claimsIcon";
import FulfillmentIcon from "../assets/icons/fulfillmentIcon";


export const getGuageIcons = (metricsLabel, secondaryColor) => {
  switch (metricsLabel?.toUpperCase()){
    case 'TRADE IN EXECUTION':
      return <TradeInExecutionIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    case 'SUBSCRIPTION':
        return <TradeInExecutionIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    case 'CONFIGURATION':
      return <TradeInExecutionIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />  
    case 'RETURNS':
      return <ReturnsIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    case 'SERVICE REQUEST':
      return <ReturnsIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    case 'DEVICE RETURNS':
      return <ReturnsIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />  
    case 'DEVICE RECEIPT':
      return <DeviceRecieptIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    case 'DEVICE PROCESSING':
      return <DeviceRecieptIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />  
    case 'MONETISATION':
    case 'MONETIZE DEVICE':
      return <MonetizeDeviceIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    case 'CHARGES($)':
      return <Dollar className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    case 'CHARGES':
      return <Dollar className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    case 'SERVICE REQUEST REVENUE':
      return <Dollar className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    case 'SETTLEMENT':
      return <SettlementIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    case 'NPS':
      return <SettlementIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    case 'CUSTOMER INQUIRIES':
      return <CutomerInquiriesIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    case 'ENROLMENT':
      return <EnrollmentIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    case 'DEVICE REGISTRATION':
      return <DeviceRegIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    case 'CLAIMS':
      return <ClaimsIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    case 'FULFILLMENT REPAIR':
    case 'FULFILLMENT':
    case 'FULFILMENT REPLACE':
    case 'FULFILLMENT BUYOUT': 
      return <FulfillmentIcon className="m-0 h-7 w-7 mr-2" stroke={secondaryColor} />
    default:
      break;
  }
}

export const getMyColors = (index) => {
  let primary = "#000000";
  let secondary = "#000000";
  let text = "#FFFFFF";
  switch (index) {
    case 0:
      primary = "#8223D2";
      secondary = "#D648FD";
      break;
    case 1:
      primary = "#6E767D";
      secondary = "#D5D6DA";
      text = "#000000";
      break;
    case 2:
      primary = "#00B574";
      secondary = "#1DFFBA";
      text = "#000000";
      break;
    case 3:
      primary = "#4A68F9";
      secondary = "#00B9FD";
      break;
    case 4:
      primary = "#82A700";
      secondary = "#D2FA46";
      text = "#000000";
      break;
    case 5:
      primary = "#A839F3";
      secondary = "#32E7FF";
      break;
    case 6:
      primary = "#9F0000";
      secondary = "#3CAFF7";
      break;
    case 7:
      primary = "#9F0000";
      secondary = "#3CAFF7";
      break;
    case 8:
      primary = "#00C9FF";
      secondary = "#92FE9D";
      text = "#000000";
      break;
    default:
      break;
  }
  return { primary, secondary, text };
};

export const isEmpty = (input) => {
  if (typeof input === "undefined" || input === "null") {
    return true;
  }
  if (typeof input === "function") {
    return false;
  }
  if (Array.isArray(input)) {
    return input.length === 0;
  }
  return !input || Object.keys(input).length === 0;
};

export const getUniqueValuesFromArray = (array, key) => {
  return array?.filter(
    (asp, index, self) => self.findIndex((a) => a[key] === asp[key]) === index
  );
};

// export const sanitize = (value) => {
//   return DOMPurify.sanitize(value);
// };

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2]?.replace(/\+/g, " "));
};

export const numberWithCommas = (number) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const toPascalCase = (str) => {
  return str?.replace(/\w\S*/g, (t) => {
    return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase()
  })
}
export const getResource = (resources, key) => {
  return resources[key] || key
}


export const setCursorPositioning = (event) => {
  // To get scroll element coordinates
  const left = document.documentElement.scrollLeft;
  const top = document.documentElement.scrollTop;

  // Current cursor position
  let x = event.pageX;
  let y = event.pageY;

  // Determining if scroll is present, subtract values from actual cursor position
  top > 0 && (y -= top);
  left > 0 && (x -= left);

  // Setting up dynamic property so that animation can start and end from our cursor position
  document.documentElement.style.setProperty(
    "--modal-origin",
    `${x}px ${y}px`
  );
};

export const getTypeIndicator = (type, country) => {
  switch(type){
    case 'Currency_Sum':
      return country?.toLowerCase() === 'thailand' ? "฿" : country?.toLowerCase() === 'malaysia' ? 'RM' : "$"
    default:
      return ""
  }
}