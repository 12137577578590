import { useState, useEffect, useRef } from "react";
import { useCentralStore } from "../../store/appContext";
import PerformanceBar from "../common/performanceBar/performanceBar";
import PerformanceCard from "../common/performanceCard/performanceCard";
import Xarrow from "react-xarrows";
import ActionTypes from "../../store/actionTypes";
import API from "../../api";
import Popup from "../common/popup/popup";
import DataTable from "../common/dataTable/dataTable";
import RecursiveComponent from "../common/recursion/recursion";
import { isEmpty, setCursorPositioning  } from "../../utils/functionUtils";
import config from "../../config/config";

const Performance = ({ className, resources }) => {
  const [
    { projectData, 
      reportCountSuccess,
      nintyDaysData,
      selectedTab, 
      selectedDashboard, 
      selectedClient, 
      selectedStore, 
      selectedDateType, 
      clientData, 
      userData, 
      userDetails, 
      childSubmatricsData, 
      submatricsDetailsData, 
      selectedDate,
      projectDataCheck
    }, dispatch] = useCentralStore();

  const [matricsColors, setMatricsColors] = useState(undefined)
  const [isOpen, openPopup] = useState(false);
  const [classInOut, setClassInOut] = useState(null);
  const [subquerykey, setSubquerykey] = useState(null);
  const [metricsLabel, setMetricsLabel] = useState(null);
  const [value, setValue] = useState(null);
  const [label, setLabel] = useState(null);
  const [colors, setColors] = useState(null);
  // const [prevLabel, setPrevLabel] = useState(null);
  // const [currentLabel, setCurrentLabel] = useState(null);
  
  const matricsColor = (colors) =>{
    setMatricsColors(colors)
  }

  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
  };

  
  // function usePrevious(value) {
  //   const r = useRef()
  //   useEffect(() => {
  //     r.current = value
  //   })
  //   return r.current
  // }
  // const setPrevLabel1 = usePrevious(currentLabel)

  const goToMatricsTier = (e,object) =>{

    // setCurrentLabel(object?.label)
    // setPrevLabel(setPrevLabel1)

    let getClientName = clientData?.find(
      (v) => v?.ClientId === selectedClient
    );

    if(object === undefined){
      dispatch({
        type: ActionTypes.GET_CHILD_SUBMATRICS_SUCCESS,
        payload: undefined,
      })
      dispatch({
        type: ActionTypes.SET_REMOVED_SUBMATRICS_DATA,
        payload: [],
      })
    } else {
      let payload = {
        DashboardId: selectedStore?.toLowerCase() == 'care' ? 'all' : selectedDashboard,
        DashboardName: selectedTab,
        Channel: selectedStore?.toLowerCase() == 'care' ? 'all' : selectedStore,
        ClientId: selectedClient,
        Client: getClientName?.Client,
        Duration: selectedDate,
        MetricsLabel: object?.label,
        // Username: 'cwr.sagar.gadwe'
        Username: userDetails && userDetails?.email?.split("@")?.[0]
      }
      setLoading(true);
      API[ActionTypes.GET_CHILD_SUBMATRICS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        setLoading(false);
        if(data){
          setLoading(false);
          dispatch({
            type: ActionTypes.GET_CHILD_SUBMATRICS_SUCCESS,
            payload: data?.getChildSubMetricsResponse,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: ActionTypes.GET_CHILD_SUBMATRICS_FAILURE,
          payload: err,
        });
        // dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: payload,
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      })
      setCursorPositioning(e)
    }
  }

  const openPopup1 = () => {
    setTimeout(() => {
      openPopup(false);
      dispatch({
        type: ActionTypes.GET_SUBMATRICS_DETAILS_SUCCESS,
        payload: [],
      });
      dispatch({
        type: ActionTypes.EXPORT_SUBMATRICS_DETAILS_SUCCESS,
        payload: undefined,
      });
    }, [2000]);
  };

  const getSubData = (e, subquerykey, MetricsLabel, value, label, colors) => {
    setSubquerykey(subquerykey)
    setMetricsLabel(childSubmatricsData?.Metrics[0]?.label)
    setValue(value)
    setLabel(label)
    setColors(colors)

    setCursorPositioning(e)
    let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);
    const NintyDaysCount = nintyDaysData?.Metrics?.find((e) => e.label === MetricsLabel)?.submetrics?.find((e) => e.label === subquerykey)?.value
    const payload = {
      StartDate: selectedDate === "LAST_30_DAYS" || selectedDate === "LAST_60_DAYS" ? projectData?.Duration?.StartDate : nintyDaysData?.Duration?.StartDate,
      EndDate: selectedDate === "LAST_30_DAYS" || selectedDate === "LAST_60_DAYS" ? projectData?.Duration?.EndDate : nintyDaysData?.Duration?.EndDate,
      QueryKey: subquerykey,
      StartingRow: 1,
      RowsCount: 10,
      Channel:
        selectedStore?.toUpperCase() === "STORE" ? "IN-STORE" : selectedStore,
      Duration: selectedDate === "LAST_30_DAYS" || selectedDate === "LAST_60_DAYS" ? projectData?.Duration?.Duration :nintyDaysData?.Duration?.Duration,
      TotalCount: selectedDate === "LAST_30_DAYS" || selectedDate === "LAST_60_DAYS" ? value?.toString() : NintyDaysCount?.toString(),
      MetricsLabel: childSubmatricsData?.Metrics[0]?.label,
      Client: getClientName?.Client,
    };
    setLoading(true);
    API[ActionTypes.GET_SUBMATRICS_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          // setRefreshedOn(moment().format("YYYY-MM-DD HH:mm:ss"));
          openPopup(true);
          setClassInOut("modal-container modal-space-in");
          dispatch({
            type: ActionTypes.GET_SUBMATRICS_DETAILS_SUCCESS,
            payload: data?.GetSubMetricsDetailsResponse,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
          payload: err,
        });
        // dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: payload,
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
    // .finally(() => {
    //   setLoading(false);
    // });
  }

  // const [stopAnimaiton1, setStopAnimation] = useState(false)
  
  // setTimeout(() => {
  //   setStopAnimation(true);
  // }, [2000]);

  // useEffect(() => {
  //   if (value) {
  //     setStopAnimation(false)      // setanimateNumber(true);
  //   }
  // }, [value]);

  // const RecursiveComponent = ({ submetrics, barObject, colors, parent }) => {
  //   return (
  //     <div className={`${parent === undefined ? 'mx-10' : ''}`} id={`${parent}`}>
  //       {submetrics?.map((submetrics, i) => {
  //         return (
  //           <div className={`flex`}>
  //             <PerformanceCard 
  //               id={submetrics.label?.replaceAll(' ','')}
  //               key={submetrics.label}
  //               label={submetrics.label}
  //               value={submetrics.value}
  //               status={submetrics.status}
  //               colors={colors}
  //               subquerykey={submetrics.subquerykey}
  //               MetricsLabel={barObject?.label}
  //               barLabel={barObject?.label}
  //               metricsCss="w-52 mr-24 ml-3 h-fit relative z-1 mt-0"
  //               subMetricsTypeIndicator={submetrics?.SubMetricsTypeIndicator}
  //               isSubMatrics={true}
  //               getSubData={(e, subquerykey, MetricsLabel, value, label) => getSubData(e, subquerykey, MetricsLabel, value, label)}
  //               resources={resources}
  //               // stopAnimaiton1={stopAnimaiton1}
  //             />
  //             {parent !== undefined &&
  //               <Xarrow
  //                   start={parent}
  //                   end={`${submetrics.label?.replaceAll(' ','')}`}
  //                   strokeWidth={2.5}
  //                   startAnchor="right"
  //                   endAnchor="left"
  //                   zIndex="-10"
  //                   color="#CB5AFF"
  //                   // dashness
  //                   // dashness={{ strokeLen: 10, nonStrokeLen: 15, animation: -2 }}
  //                   // showHead={false}
  //                   headSize={5}
  //                   animateDrawing={0.1}
  //                   path={"grid"}
  //                   // curveness={1}
  //               />
  //             }
  //             {submetrics?.submetrics && <>
  //               <RecursiveComponent parent={submetrics.label?.replaceAll(' ','')} submetrics={submetrics.submetrics} index={i} />
  //             </>}
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // }

  // projectDataCheck && projectData && (isEmpty(projectData) || projectData?.Metrics?.length == 0) ?
  //         <p className="text-center w-full">Data refresh in progress, please try again</p>
  //         :
  
  // const [totalWidth, setTotalWidth] = useState(window.innerWidth)
  // useEffect(() =>{
  //   setTotalWidth(totalWidth + 200)
  // }, [!isEmpty(childSubmatricsData)])

  // const[__projectData, setProectData] = useState([])
  
  // useEffect(() => {
  //   if(!isEmpty(reportCountSuccess)){
  //     let _data = reportCountSuccess?.Metrics
  //     let removeFromArr = ['CONFIGURATION','CLAIMS', 'MONETIZE DEVICE', 'CHARGES']
  //     let _arr = _data.filter(function( obj ) { return !removeFromArr.includes(obj.label.toUpperCase());});
  //     setProectData(_arr)
  //   }
  // }, [reportCountSuccess])
  
  return (
    <>
    {/* <div className={`bgImage ${!isEmpty(submatricsDetailsData) ? '' : 'MetricsScrollClass MetricsScroll'} mt-5`} id=""> */}
    <div className={`${config.isSGP === 'true' ? '' : 'bgImage'} `}
      // style={{
      //   width: `${totalWidth}'px'`
      // }}
      >
    <>
      {(projectData || childSubmatricsData) && (
        // <div className={`flex overflow-x-auto ${!isEmpty(submatricsDetailsData) ? '' : 'ContentMetricsScroll'} ${className}`}>
        <div className={`flex overflow-x-auto ${className}`}>
          <>
          {config.isSGP === 'false' && projectData && projectData?.Metrics && projectData?.Metrics?.map((m, i) => (
              <PerformanceBar
                key={m.label}
                barObject={m}
                performanceIndex={i}
                nextIndex={i + 1}
                hideLastArrow={i === projectData?.Metrics?.length - 1}
                projectData={projectData}
                selectedTab={selectedTab}
                goToMatricsTier={(e, label) => goToMatricsTier(e, label)}
                matricsColor={(colors)=> matricsColor(colors)}
                submetrics={childSubmatricsData}
                resources={resources}
                // prevLabel={prevLabel}
                getSubData={getSubData}
                dispatch={dispatch}
                matricsObj={childSubmatricsData?.Metrics[0]}
                // classInOut={classInOut}
                submatricsDetailsData={submatricsDetailsData}
                // selectedDate={selectedDate}
                // subquerykey={subquerykey}
                // openPopup1={openPopup1}
                // setClassInOut={setClassInOut}
                clientData={clientData}
                selectedClient={selectedClient}
                selectedStore={selectedStore}
                userData={userData}
                selectedDate={selectedDate}
              />
            ))}
          </>
        </div>
      )}
      {/* <div className={`flex overflow-x-auto ${className}`}>
        {config.isSGP === 'true' && !isEmpty(reportCountSuccess) && reportCountSuccess?.Metrics?.map((m, i) => (
          <PerformanceBar
            key={m.label}
            barObject={m}
            performanceIndex={i}
            nextIndex={i + 1}
            hideLastArrow={i === reportCountSuccess?.Metrics?.length - 1}
            projectData={reportCountSuccess}
            selectedTab={selectedTab}
            goToMatricsTier={(e, label) => goToMatricsTier(e, label)}
            matricsColor={(colors)=> matricsColor(colors)}
            submetrics={childSubmatricsData}
            resources={resources}
            // prevLabel={prevLabel}
            getSubData={getSubData}
            dispatch={dispatch}
            matricsObj={childSubmatricsData?.Metrics[0]}
            // classInOut={classInOut}
            submatricsDetailsData={submatricsDetailsData}
            // selectedDate={selectedDate}
            // subquerykey={subquerykey}
            // openPopup1={openPopup1}
            // setClassInOut={setClassInOut}
            clientData={clientData}
            selectedClient={selectedClient}
            selectedStore={selectedStore}
            userData={userData}
            selectedDate={selectedDate}
          />
        ))}
        </div> */}
    </>
      {/* {!childSubmatricsData ? 
        <>
          {projectData && (
            // <div className={`flex overflow-x-auto ${!isEmpty(submatricsDetailsData) ? '' : 'ContentMetricsScroll'} ${className}`}>
            <div className={`flex overflow-x-auto ${className}`}>
              {projectData && projectData?.Metrics && projectData?.Metrics?.map((m, i) => (
                  <PerformanceBar
                    key={m.label}
                    barObject={m}
                    performanceIndex={i}
                    nextIndex={i + 1}
                    hideLastArrow={i === projectData?.Metrics?.length - 1}
                    projectData={projectData}
                    selectedTab={selectedTab}
                    goToMatricsTier={(e, label) => goToMatricsTier(e, label)}
                    matricsColor={(colors)=> matricsColor(colors)}
                    submetrics={childSubmatricsData}
                    resources={resources}
                    // prevLabel={prevLabel}
                  />
                ))}
            </div>
          )}
        </>
        :
        <div className={`flex flex-col overflow-x-auto ${className} metricsAnimation`}>
          <PerformanceBar
            key={childSubmatricsData?.Metrics[0].label}
            barObject={childSubmatricsData?.Metrics[0]}
            performanceIndex={""}
            nextIndex={""}
            hideLastArrow={false}
            projectData={projectData}
            selectedTab={selectedTab}
            goToMatricsTier={(obj) => goToMatricsTier(obj)}
            matricsObj={childSubmatricsData?.Metrics[0]}
            matricsColor={(colors)=> matricsColor(colors)}
            matricsColors={matricsColors}
            submetrics={childSubmatricsData}
            dispatch={dispatch}
            ActionTypes={ActionTypes}
            resources={resources}
          />
          <RecursiveComponent submetrics={childSubmatricsData?.Metrics[0].submetrics} barObject={childSubmatricsData?.Metrics[0]} colors={matricsColors} getSubData={getSubData} resources={resources}/>
          <Popup
            show={isOpen}
            global={true}
            className={`rounded-xl ${classInOut}`}
            fitContent={true}
            // onClose={() => openPopup(false)}
            submatricsDetailsData={submatricsDetailsData}
            submatrics={true}
          >
            {(isOpen && submatricsDetailsData) && (
              <DataTable
                submatricsDetailsData={submatricsDetailsData}
                selectedDate={selectedDate}
                subquerykey={subquerykey}
                label={label}
                value={value}
                colors={colors}
                MetricsLabel={metricsLabel}
                onClose={(e) => ( openPopup1(), setClassInOut("modal-container modal-space-out"), e.stopPropagation()
                )}
                resources={resources}
              />
            )}
          </Popup>
        </div>
      } */}
    </div>
    </>
  );
};

export default Performance;
