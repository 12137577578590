import { useEffect } from "react";
import { useState } from "react";
import { ReactComponent as DownArrow } from "../../../assets/icons/down-arrow.svg";

// const Selector1 = ({ label, value, options = [], onSelect, placeholder }) => (
//   <div className="flex px-3 py-2 mr-4 border rounded-lg border-app-border last:mr-0">
//     <div className="pr-4 text-app-label">{label}</div>
//     <select
//       className="font-bold text-white bg-transparent outline-none cursor-pointer"
//       value={value}
//       onChange={(e) => onSelect(e.target.value)}
//     >
//       {placeholder && (
//         <option
//           className="font-normal"
//           key={`key-${value}`}
//           selected
//           disabled
//           value=""
//         >
//           {placeholder}
//         </option>
//       )}
//       {options.map((option) => {
//         const { name, value } = option;
//         return (
//           <option key={`key-${value}`} value={value}>
//             {name}
//           </option>
//         );
//       })}
//     </select>
//   </div>
// );

const Selector = ({
  label,
  value: selectedValue,
  options = [],
  onSelect,
  placeholder,
  isStarhub
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleShowOptions = (e) => {
    e.stopPropagation();
    setShowOptions(true);
  };

  const handleOptionSelection = (e, value) => {
    e.stopPropagation();
    onSelect(e?.target?.innerText?.replace(" ", "_")?.toUpperCase(), value);
    // onSelect(value);
    setShowOptions(false);
  };

  useEffect(() => {
    const handleClick = () => {
      setShowOptions(false);
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div className="relative mr-4 w-fit">
      <div className={`flex px-3 py-2 border rounded-lg border-app-border last:mr-0 ${isStarhub ? 'border-app-greenBorder' : 'border-app-border'}`}>
        <div className="pr-4 text-app-label">{label}</div>
        <div className="flex cursor-pointer" onClick={handleShowOptions}>
          <div className="pr-2 font-bold bg-transparent outline-none">
            {options.find((s) => s.value === selectedValue)?.name ||
              placeholder}
          </div>
          <DownArrow />
        </div>
      </div>
      {showOptions && (
        <div className="absolute right-0 z-10 w-full rounded-xl">
          <Option
            key={`key-${placeholder}`}
            isSelected={selectedValue === ""}
            value=""
            disabled
            name={placeholder}
          />
          {options.map(({ name, value }) => (
            <Option
              key={`key-${value}`}
              isSelected={selectedValue === value}
              value={value}
              name={name}
              onSelect={handleOptionSelection}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Option = ({ name, value, isSelected, onSelect, disabled }) => (
  <div
    className={`py-2 px-4 first:rounded-t-md last:rounded-b-md cursor-pointer w-full border-b last:border-b-0 text-black border-app-border hover:bg-blue-200 ${
      isSelected ? "bg-blue-300 font-bold" : "bg-white"
    } ${disabled ? "bg-gray-300 text-gray-400 cursor-not-allowed" : ""}`}
    onClick={disabled ? undefined : (e) => onSelect(e, value)}
  >
    {name}
  </div>
);

export default Selector;
