import { useState, useEffect } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { useCentralStore } from "../../store/appContext";
import ActionTypes from "../../store/actionTypes";
import { useNavigate } from "react-router-dom";
import { getParameterByName, isEmpty } from "../../utils/functionUtils";
import API from "../../api";
import OtpInput from "./otpInput";
import Spinner from "../../components/common/spinner/spinner";

const OtpPage = ({}) => {
  const [{ userLoggedIn, authCodeSearch, loadingData }, dispatch] =
    useCentralStore();

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
  };
  const [otp, setOtpvalue] = useState();
  const [otpErrors, setOtpError] = useState();
  const getotpvalue = (otp) => {
    setOtpvalue(otp);
  };

  const otpError = () => {
    setLoading(true);
    if (otp.length === 6) {
      if (otp.join("") !== "202301") {
        setLoading(false);
        setOtpError("Invalid OTP");
      } else {
        navigate("/");
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loadingData && <Spinner showOverlay={true} />}
      <div className="bg-white h-screen text-black">
        <div className="background-container">
          <div className="boxone"></div>
          <div className=" box   "></div>
          <div className="flex justify-evenly">
            <div className="">
              <img
                className="mx-auto mt-28 h-32 w-32"
                src={require("../../assets/Starhub/otpStar.png")}
              />
              <p className="text-black mt-5 text-center">
                A code has been sent to your StarHub email.<br></br> Please
                enter the code that was sent to you below.
              </p>
              <OtpInput
                value="202301"
                otpLength={6}
                fontClasses={"font-semibold"}
                getotpvalue={getotpvalue}
              />

              <div className="mt-10 mx-m15 flex justify-center">
                <button
                  id="continue"
                  className="px-4 py-2 bg-app-lightGreen mx-20 w-full rounded-lg"
                  type="submit"
                  onClick={otpError}
                >
                  Continue
                </button>
              </div>
              {otpErrors && <p className="error">{otpErrors}</p>}

              <div className="text-center mt-10">
                <a
                  className="cursor-not-allowed text-center mt-10"
                  href=""
                  target="_blank"
                >
                  Resend Code
                </a>
              </div>
            </div>
            <div className="flex justify-end">
            </div>
            <img className="h-screen" src={require('../../assets/icons/OtpImage.png')}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpPage;