import moment from "moment";
import { useEffect, useState } from "react";
import API from "../../api";
import Selector from "../../components/common/selector/selector";
import ActionTypes from "../../store/actionTypes";
import { useCentralStore } from "../../store/appContext";
import { isEmpty } from "../../utils/functionUtils";
import WelcomeSection from "./welcomeSection";
import { toPascalCase } from "../../utils/functionUtils";
import config from "../../config/config";

let clientChangeTimerId = "";

const StoreSelection = ({
  className,
  selectedTab,
  resources,
  goToHomePage,
  allDashboards,
}) => {
  const [
    {
      clientList,
      storeList,
      selectedClient,
      selectedDashboard,
      selectedStore,
      selectedDate,
      selectedDateType,
      clientData,
      userData,
      userDetails,
      loadingPer,
      fullscreenMode,
      isTradeDashboardOpen,
      uniqueClientsData,
      allDashboardsList,
      uniqueClientsList,
      mapSelected,
      favouriteData,
      reportFilterDuration
    },
    dispatch,
  ] = useCentralStore();

  let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);
  const ClientName = getClientName?.Client

  const [firstLoad, setFirstLoad] = useState(true);
  const [refreshedOn, setRefreshedOn] = useState(null);
  const [recurringFetch, setRecurringFetch] = useState(false);
  const [clientName, setClientName] = useState("");

  const clients = clientList.map((c) => c.value);
  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING_PER, payload: action });
  };

  useEffect(() => {
    let stores = [
      {
        name: "All",
        value: "all",
        dashboardId: "all",
        label: uniqueClientsList?.find((f) => f.ClientId === selectedClient)
          ?.Channels?.[0]?.Label,
      },
    ];

    uniqueClientsList
      ?.find((f) => f.ClientId === selectedClient)
      ?.Channels?.map((c) =>
        stores?.push({
          name: c.Channel,
          value: c.Channel,
          AllDashboards: c.AllDashboards,
          label: c.Label,
        })
      );
    dispatch({ type: ActionTypes.SET_STORES_LIST, payload: stores });
  }, [uniqueClientsList]);

  const getDashboardData = () => {

    if (selectedClient && selectedStore && selectedDate && selectedDashboard) {
      dispatch({
        type: ActionTypes.GET_CHILD_SUBMATRICS_SUCCESS,
        payload: undefined,
      });

      let getClientName = clientData?.find(
        (v) => v?.ClientId === selectedClient
      );
      setClientName(getClientName?.Client);
      const payload = {
        // To: moment().format("YYYY-MM-DD 00:00:00"),
        // From: selectedDate,
        DashboardId:
          selectedStore?.toLowerCase() == "care" ? "all" : selectedDashboard,
        DashboardName: selectedTab,
        Channel: selectedStore?.toLowerCase() == "care" ? "all" : selectedStore,
        ClientId: selectedClient,
        Client: getClientName?.Client,
        // Label: selectedDateType,
        Duration: selectedDate || resources.LAST_90_DAYS_VALUE,
        // Client: "Samsung",
        // Username: "shreesh.nirgudkar",
      };

      setLoading(true);
      dispatch({
        type: ActionTypes.SET_DASHBOARD_LOADING,
        payload: true,
      });
      API[ActionTypes.GET_DASHBOARD_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
        .then(({ data }) => {
          if (data) {
            setRefreshedOn(moment().format("YYYY-MM-DD HH:mm:ss"));
            dispatch({
              type: ActionTypes.SET_DASHBOARD_LOADING,
              payload: false,
            });
            dispatch({
              type: ActionTypes.SET_REFRESHED_ON,
              payload: moment().format("YYYY-MM-DD HH:mm:ss"),
            });
            dispatch({
              type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
              payload: data?.GetDashboardResponse,
            });
            dispatch({
              type: ActionTypes.GET_DASHBOARD_DETAILS_DATA_SUCCESS,
              payload: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          dispatch({
            type: ActionTypes.SET_DASHBOARD_LOADING,
            payload: false,
          });
          dispatch({
            type: ActionTypes.GET_DASHBOARD_DETAILS_FAILURE,
            payload: err,
          });
          // dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
          dispatch({
            type: ActionTypes.GET_DASHBOARD_DETAILS_DATA_SUCCESS,
            payload: true,
          });
          let _payload = {
            level: resources.POST_LOGGER_ERROR,
            category: resources.POST_LOGGER_API,
            ApiName: resources.POST_LOGGER_APINAME,
            message: {
              error: err,
              info: payload,
            },
          };
          API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
          dispatch({
            type: ActionTypes.SET_REFRESHED_ON,
            payload: moment().format("YYYY-MM-DD HH:mm:ss"),
          });
        })
        .finally(() => {
          setLoading(false);
          setFirstLoad(false);
        });
    }
  };

  useEffect(() => {
    if (
      // [
      //   resources.BPM_DASHBOARD_LBL,
      //   resources.OPS_DASHBOARD_LBL,
      //   resources.HOME_PLUS_BPM_DASHBOARD_LBL,
      //   resources.HOME_PLUS_OPS_DASHBOARD_LBL,
      //   resources.STARHUB_BPM_DASHBOARD_LBL,
      //   resources.STARHUB_OPS_DASHBOARD_LBL
      // ].includes(selectedTab)
      selectedTab
    )
      if(mapSelected?.toLowerCase() !== 'australia'){
        const clientSet = uniqueClientsList?.map(c => {
          if(c?.Channels[0].AllDashboards?.find(v => v?.Label == selectedTab)){
            return c
          }
        })
        const setClient = clientSet?.filter(function( element ) {
          return element !== undefined;
        });
        dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: setClient?.[0]?.ClientId });
      }
      setFirstLoad(true);
  }, [selectedTab]);

  useEffect(() => {
    if (
      firstLoad &&
      !isEmpty(selectedClient) &&
      !isEmpty(selectedStore) &&
      !isEmpty(selectedDate) &&
      !isEmpty(selectedTab)
    ) {
      setLoading(true);
      getDashboardData();
      // setRecurringFetch(true);
    }
    let interval = setInterval(() => {
      if (
        !isEmpty(selectedClient) &&
        !isEmpty(selectedStore) &&
        !isEmpty(selectedDate) &&
        !isEmpty(selectedTab)
        // && fullscreenMode === true
      ) {
        // if (recurringFetch) {
        getDashboardData();
        // } else {
        //   clearInterval(interval);
        // }
      }
      // }, 180000);
    // }, fullscreenMode ? 600000 : 180000);
  }, 600000);
    // }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [
    selectedClient,
    selectedStore,
    selectedDate,
    firstLoad,
    fullscreenMode,
    // recurringFetch,
  ]);

  const dates = [
    // {
    //   name: resources.TODAY_LBL,
    //   value: resources.TODAY_VALUE,
    // },
    // {
    //   name: resources.LAST_7_DAYS_LBL,
    //   value: resources.LAST_7_DAYS_VALUE,
    // },
    {
      name: resources.LAST_30_DAYS_LBL,
      value: resources.LAST_30_DAYS_VALUE,
    },
    {
      name: resources.LAST_60_DAYS_LBL,
      value: resources.LAST_60_DAYS_VALUE,
    },
    {
      name: resources.LAST_90_DAYS_LBL,
      value: resources.LAST_90_DAYS_VALUE,
    },
    // {
    //   name: resources.THIS_MONTH_LBL,
    //   value: resources.THIS_MONTH_VALUE,
    //   // value: moment().startOf("month").format("YYYY-MM-DD 00:00:00"),
    // // },
    {
      name: resources.THIS_YEAR_LBL,
      value: resources.THIS_YEAR_VALUE,
      // value: moment().startOf("years").format("YYYY-MM-DD 00:00:00"),
    },
    {
      name: resources.SINCE_LAUNCH_LBL,
      value: resources.SINCE_LAUNCH_VALUE,
      // value: moment("2020-01-01").format("YYYY-MM-DD 00:00:00"),
    },
  ];

  const datesWoSinceLaunch = [
    {
      name: resources.LAST_30_DAYS_LBL,
      value: resources.LAST_30_DAYS_VALUE,
    },
    {
      name: resources.LAST_60_DAYS_LBL,
      value: resources.LAST_60_DAYS_VALUE,
    },
    {
      name: resources.LAST_90_DAYS_LBL,
      value: resources.LAST_90_DAYS_VALUE,
    },
    {
      name: resources.THIS_YEAR_LBL,
      value: resources.THIS_YEAR_VALUE,
    },
  ];

  // useEffect(()=>{
  //   dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: "F50D60E6F9D611E8A8F20A86007B17AC" });
  //   dispatch({ type: ActionTypes.SELECTED_STORE, payload: 'Online' });
  //   dispatch({ type: ActionTypes.SELECTED_DASHBOARD_DATE, payload: {name:'THIS_YEAR',value:'THIS_YEAR'} });
  // },[clientData])

  useEffect(() => {
    clientChangeTimerId && clearTimeout(clientChangeTimerId);
    // if(selectedClient) {
    dispatch({
      type: ActionTypes.SELECTED_STORE,
      payload: selectedStore || "all",
    });
    dispatch({
      type: ActionTypes.SELECTED_DASHBOARD_DATE,
      payload: {
        name: selectedDate || resources.LAST_90_DAYS_VALUE,
        value: selectedDate || resources.LAST_90_DAYS_VALUE,
      },
    });
    setFirstLoad(true);
    // if (selectedClient) {
    //   clientChangeTimerId = setTimeout(() => {
    //     const nextClientIndex =
    //       clients.indexOf(selectedClient) === clients.length - 1
    //         ? 0
    //         : clients.indexOf(selectedClient) + 1;
    //     const nextClient = clients[nextClientIndex];
    //     dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: nextClient });
    //   }, 600000);
    // } else {
    //   const samsungClient = clientList.find((c) => c.name === "Samsung");
    //   const defaultClient = samsungClient?.value || clientList[0]?.value;
    //   defaultClient &&
    //     dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: defaultClient });
    // }
    // }
    // }, [selectedClient, clientList]);
  }, [selectedClient, uniqueClientsData]);

  const handleClientSelect = (name, value) => {

    if(mapSelected?.toLowerCase() !== 'australia'){
      let setDashboard = uniqueClientsList?.find(v => v?.ClientId === value)?.Channels[0]?.AllDashboards[0]
      dispatch({ type: ActionTypes.SET_SELECTED_DASHBOARD, payload: setDashboard?.DashboardId })
      dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: setDashboard?.Label })
    }

    dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: value });
    setRefreshedOn(null);
    dispatch({
      type: ActionTypes.SET_REFRESHED_ON,
      payload: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setRecurringFetch(false);
    let getClientName = clientData?.find((v) => v?.ClientId === value);
    let client = getClientName?.Client;
    let Name = clientName
    setClientName(client);
    if (Name === client) { 
      getDashboardData()
    }
  };

  const handleStoreSelect = (name, value) => {
    dispatch({ type: ActionTypes.SELECTED_STORE, payload: value });
    setRefreshedOn(null);
    dispatch({
      type: ActionTypes.SET_REFRESHED_ON,
      payload: null,
    });
    setRecurringFetch(false);
    setFirstLoad(true);
  };

  const handleDateSelect = (name, value) => {
    dispatch({
      type: ActionTypes.SELECTED_DASHBOARD_DATE,
      payload: { name, value },
    });

    // let days = value === 'LAST_30_DAYS' ? 30 : value === 'LAST_60_DAYS' ? 60 : value === 'LAST_90_DAYS' ? 90 : 90

    // let startDate = moment(new Date(Date.now() - days * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD 00:00:00')
    // let endDate = moment(new Date()).format('YYYY-MM-DD 23:59:59')
    // // console.log('startDate', startDate, '-----',endDate);
    // dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION, payload: {
    //     type: 'dates',
    //     startDate: startDate,
    //     endDate: endDate,
    //     duration: 'dates'
    // }})

    setFirstLoad(true);
    setRefreshedOn(null);
    dispatch({
      type: ActionTypes.SET_REFRESHED_ON,
      payload: null,
    });
    setRecurringFetch(false);
  };

  useEffect(() => {
    if(!isEmpty(favouriteData)){
      dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: favouriteData?.ClientId });
    }
  }, [favouriteData])  

  // useEffect(() => {
  //   let payload = {
  //     StartDate: reportFilterDuration?.startDate,
  //     EndDate: reportFilterDuration?.endDate,
  //     // StartDate: '2023-05-01 00:00:00',
  //     // EndDate: '2023-05-31 23:59:59',
  //     ReportType: 'All'
  // };
  // setLoading(true)
  // API[ActionTypes.GET_STARHUB_REPORT_COUNT](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
  // .then(({ data }) => {
  //     setLoading(false);
  //     if (data) {
  //       dispatch({
  //         type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS,
  //         payload: data,
  //       });
  //     }
  //   })
  //   .catch((err) => {
  //     dispatch({
  //       type: ActionTypes.GET_STARHUB_REPORT_COUNT_FAILURE,
  //       payload: err,
  //     });
  //     dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
  //     let _payload = {
  //       level: resources.POST_LOGGER_ERROR,
  //       category: resources.POST_LOGGER_API,
  //       ApiName: resources.POST_LOGGER_APINAME,
  //       message: {
  //         error: err,
  //         info: {
  //           idToken: "",
  //         },
  //       },
  //     };
  //     API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
  //   });
  // }, [reportFilterDuration])

  // console.log('store reportFilterDuration', reportFilterDuration);

  const handleLiveTab = () => {
    dispatch({
      type: ActionTypes.SET_SELECTED_TAB,
      payload: 'StarHub BPM Performance',
    })
    let days = selectedDate === 'LAST_30_DAYS' ? 30 : selectedDate === 'LAST_60_DAYS' ? 60 : selectedDate === 'LAST_90_DAYS' ? 90 : 90
    let startDate = moment(new Date(Date.now() - days * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD 00:00:00')
    let endDate = moment(new Date()).format('YYYY-MM-DD 23:59:59')

    dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION, payload: {
        type: 'dates',
        startDate: startDate,
        endDate: endDate,
        duration: 'dates'
    }})

  }

  return (
    <div className={` ${className} flex flex-col`}>
      {/* <WelcomeSection /> */}
      {/* {isTradeDashboardOpen && (
        <div className="flex flex-row relative left-6% mb-8 w-fit">
          <p
            className={`cursor-pointer mr-2 p-2 border border-app-lightBlue1 rounded-lg text-xs ${
              selectedTab === "MAP"
                ? "text-white bg-app-lightBlue"
                : "text-app-lightBlue1"
            } `}
            onClick={() => {
              dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "MAP" });
              dispatch({ type: ActionTypes.SET_PROGRAM_NAME, data: "" });
              dispatch({
                type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
                payload: null,
              });
              goToHomePage();
              dispatch({
                type: ActionTypes.GET_CHILD_SUBMATRICS_SUCCESS,
                payload: undefined,
              });

              dispatch({
                type: ActionTypes.SELECTED_STORE,
                payload: "all",
              });
              dispatch({
                type: ActionTypes.SELECTED_DASHBOARD_DATE,
                payload: {
                  name: resources.SINCE_LAUNCH_VALUE,
                  value: resources.SINCE_LAUNCH_VALUE,
                },
              });
            }}
          >
            Select country
          </p>
          {allDashboards?.map((v) => (
            <>
              <p
                className={`cursor-pointer text-xs mr-2 p-2 border border-app-lightBlue1 rounded-lg ${
                  selectedTab === v?.Label
                    ? "text-white bg-app-lightBlue"
                    : "text-app-lightBlue1"
                }`}
                onClick={() => {
                  dispatch({
                    type: ActionTypes.SET_SELECTED_TAB,
                    payload: v?.Label,
                  });
                  dispatch({
                    type: ActionTypes.GET_CHILD_SUBMATRICS_SUCCESS,
                    payload: undefined,
                  });
                  dispatch({
                    type: ActionTypes.SELECTED_STORE,
                    payload: selectedStore || "all",
                  });
                  dispatch({
                    type: ActionTypes.SELECTED_DASHBOARD_DATE,
                    payload: {
                      name: selectedDate || resources.SINCE_LAUNCH_VALUE,
                      value: selectedDate || resources.SINCE_LAUNCH_VALUE,
                    },
                  });
                  // dispatch({
                  //   type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
                  //   payload: undefined,
                  // });
                }}
              >
                {v?.Label == resources.BPM_DASHBOARD_LBL
                  ? "Trade-In Business Process Model"
                  : "Operations Performance Monitoring"}
              </p>
            </>
          ))}
        </div>
      )} */}
      <div className="flex justify-between px-4 items-center">
        <div className="flex">
          {config.isSGP === 'false' ?
            <Selector
              key="Client Dropdown"
              options={uniqueClientsData}
              label="Client"
              value={selectedClient}
              onSelect={(name, val) => handleClientSelect(name, val)}
              // onSelect={handleClientSelect}
              placeholder="Select Client"
            />
            :
            <></>
            // <div className="flex flex-row ml-[70px]">
            //   <div className={`rounded-md text-sm cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 font-semibold
            //     ${selectedTab === 'StarHub BPM Performance' ? 'border border-app-lightGreen' : ''}`}
            //     onClick={() => handleLiveTab()}>
            //     {/* Device Protection */}
            //     Live
            //   </div>
            //   <div className={`rounded-md text-sm cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 font-semibold
            //   ${selectedTab === 'TREND' ? 'border border-app-lightGreen' : ''}`}
            //   onClick={() => (dispatch({
            //     type: ActionTypes.SET_SELECTED_TAB,
            //     payload: 'TREND',
            //   }),
            //   dispatch({
            //     type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
            //     payload: null,
            //   }),
            //   dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS, payload: null}),
            //   dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS, payload: null}),
            //   dispatch({ type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS, payload: null}))}>
            //     {/* HomePlus */}
            //     Trend
            //   </div>
            // </div>
          }
          {(!storeList?.some((c) => c?.name?.toUpperCase() == "NA") && mapSelected?.toLowerCase() ===  'australia') && (
            <Selector
              key="Channel Dropdown"
              options={storeList}
              label="Channels"
              value={selectedStore}
              onSelect={(name, val) => handleStoreSelect(name, val)}
              // onSelect={handleStoreSelect}
              placeholder="Select Channel"
            />
          )}
        </div>
        <div></div>

        {(selectedTab === "StarHub BPM Performance" || selectedTab === "StarHub Operational Performance" || selectedTab === 'Singtel BPM Performance' || selectedTab === 'Singtel Operational Performance'|| selectedTab === 'M1 BPM Performance' || selectedTab === 'M1 Operational Performance' || selectedTab === 'AIS BPM Performance' || selectedTab === 'AIS Operational Performance' || selectedTab === 'TRUE BPM Performance' || selectedTab === 'TRUE Operational Performance' || selectedTab === 'Asurion_TechCare BPM Performance' || selectedTab === 'Asurion_TechCare Operational Performance' || selectedTab === 'Celcom BPM Performance' || selectedTab === 'Celcom Operational Performance' || selectedTab === 'FET BPM Performance' || selectedTab === 'FET Operational Performance') ? 
          <div className="flex">
            <Selector
              key="Date Dropdown"
              options={datesWoSinceLaunch}
              label= {mapSelected?.toLowerCase() !== 'australia' ? "Calendar Days" : "Trade Creation Date"}
              value={selectedDate !== resources.SINCE_LAUNCH_VALUE ? selectedDate : resources.LAST_90_DAYS_VALUE}
              onSelect={(name, val) => handleDateSelect(name, val)}
              // onSelect={handleDateSelect}
              placeholder="Select"
            />
          </div>
          :
          <div className="flex">
            <Selector
              key="Date Dropdown"
              options={dates}
              label= {mapSelected?.toLowerCase() !== 'australia' ? "Calendar Days" : "Trade Creation Date"}
              value={selectedDate}
              onSelect={(name, val) => handleDateSelect(name, val)}
              // onSelect={handleDateSelect}
              placeholder="Select"
            />
          </div>
        }
      </div>
    </div>
  );
};

export default StoreSelection;
