import { DatePicker } from 'antd'
import API from '../../api'
import moment from 'moment'
import { useState } from 'react'
import CrossIcon from '../../assets/icons/cross-icon'
import ActionTypes from '../../store/actionTypes'
import { useCentralStore } from '../../store/appContext'
import SGPReportsFilter from '../operationalReport/SGPReportsFilter'
import resources from '../../components/common/resources.json'
import axios from 'axios'

const DownloadReports = () => {

    const [ { selectedDownloadReportDuration, selectedDownloadReportDurationLBL, userData, userDetails }, dispatch] = useCentralStore()

    const setLoading = (val) => {
        dispatch({
            type: ActionTypes.SET_LOADING,
            payload: val,
          });
    }

    let filterOptions = [
      {
        label: 'Monthly',
        value: 'monthly'
      },
      {
        label: 'Quarterly',
        value: 'quarterly'
      }]

    const [dates, setDates] = useState(null);
    const [value, setValue] = useState(null);

    const currentMonth = moment().month();
    const currentYear = moment().year();
    const disabledMonth = (current) => {
        const month = current.month();
        const year = current.year();
        return year > currentYear || (year === currentYear && month > currentMonth);
      };

    const disabledQuarter = (current) => {
        const month = current.month();
        const year = current.year();
        return year > currentYear || (year === currentYear && month > currentMonth);
    };

    const handleDateChange = (dates, dateString, type) => {
        dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION, payload: {
            type: type,
            duration: dateString
        }})
        setDates(dates);
    };

    const handleFilterChange = (val) => {
        dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION_LABEL, payload: val})
        dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION, payload: {
            type: undefined,
            duration: undefined
        }})
        setDates(null)
    }

    const closeDownloadPopup = () => {
        dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: 'Real Time' })
        dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION_LABEL, payload: undefined})
        dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION, payload: {
            type: undefined,
            duration: undefined
        }})
        setDates(null)
    }

    const downloadReport = () => {
        let payload = {
            ReportType: "All",
            Range: selectedDownloadReportDuration?.duration
        }
        setLoading(true);
        
        API[ActionTypes.SGP_DOWNLOAD_REPORT](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
        .then(({ data }) => {
          if (data) {
            axios({
                url: data?.DownloadReportResponse?.Result, //your url
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                setLoading(false);
                const href = URL.createObjectURL(response.data);
            
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `Report_data_${selectedDownloadReportDuration?.duration}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
            
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                closeDownloadPopup()
            })
            .catch((e)=>{
                setLoading(false);
                closeDownloadPopup()
            })
          }
        })
        .catch((err) => {
          setLoading(false);
          closeDownloadPopup()
          dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
          let _payload = {
            level: resources.POST_LOGGER_ERROR,
            category: resources.POST_LOGGER_API,
            ApiName: resources.POST_LOGGER_APINAME,
            message: {
              error: err,
              info: {
                idToken: "",
              },
            },
          };
          API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
        });
    } 
    return(
        <div className=' p-4'>
            <div className='flex flex-row justify-between items-center'>
                <p className="text-black text-sm font-semibold">DownloadReports</p>
                <CrossIcon className="cursor-pointer" onClick={() => closeDownloadPopup()} />
            </div>
            <div>
                <div className='flex flex-row my-2'>
                    {/* {(isEmpty(selectedReportFilter) || selectedReportFilter === 'real time' || selectedReportFilter === 'monthly'  || selectedReportFilter === 'quarterly') && ((selectedTab === "Prev Month" || selectedTab === 'Program Summary') ? filterOptionsMonthly : filterOptions)?.map(v =>  */}
                    {filterOptions?.map(v =>
                        <div className={`text-black relative rounded-md text-[11px] cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-starhubGreen mr-5 font-semibold
                            ${selectedDownloadReportDurationLBL === v.value ? 'border border-app-starhubGreen' : ''}`}
                            onClick={() => handleFilterChange(v.value)}
                        >
                            {v.label}
                        </div>
                    )}
                </div>
                {selectedDownloadReportDurationLBL === 'monthly' &&
                    <DatePicker
                        allowClear={false}
                        className="lef-[15%]"
                        picker="month"
                        onChange={(d, ds, t) => (handleDateChange(d, ds, 'month'))}
                        value={dates}
                        disabledDate={disabledMonth}
                    />
                }
                {selectedDownloadReportDurationLBL === 'quarterly' &&
                    <DatePicker
                        allowClear={false}
                        picker="quarter" 
                        className="left-[20%]"
                        onChange={(d, ds, t) => (handleDateChange(d, ds, 'quarter'))}
                        disabledDate={disabledQuarter}
                    />
                }
            </div>
            <p className={`${selectedDownloadReportDuration?.duration !== undefined ? 'bg-app-starhubGreen cursor-pointer' : 'bg-gray-300 cursor-not-allowed'} px-4 py-2 rounded-md text-sm text-center w-1/2 mx-auto my-4`}
                onClick={() => selectedDownloadReportDuration?.duration !== undefined ? downloadReport() : undefined} >
                Download Report
            </p>
        </div>
    )
}

export default DownloadReports