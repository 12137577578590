import Header from "../components/common/header/header";
import Spinner from "../components/common/spinner/spinner";
import { useCentralStore } from "../store/appContext";
import Dashboard from "./dashboard/dashboard";

const Application = () => {
  const [{ loading, loadingData }] = useCentralStore();
  return (
    <>
      {(loading || loadingData) && <Spinner showOverlay={true} />}
      <div>
        {/* <Header /> */}
        <Dashboard />
      </div>
    </>
  );
};

export default Application;
