// import { toPascalCase, getResource } from "../../../utils/functionUtils";
// import GreenNotification from "../../../assets/icons/greenNot";
// import RedNotification from "../../../assets/icons/redNot";
// import AmbernNotification from "../../../assets/icons/amberNot";
// import DeleteIcon from "../../../assets/icons/cross-icon";

// const NotificationsData = ({ notifications, resources }) => {

//   const gotoDashboard = (notifications) => {
//     console.log('Dashboard', notifications)
//   }

//   const markAsRead = (notifications) => {
//     console.log('markAsRead', notifications)
//   }

//   const deleteNotification = (notifications) => {
//     console.log('deleteNotification', notifications)
//   }

//   return (
//     <div className="flex flex-col">
//       <div className="flex flex-row justify-between">
//         {notifications?.Color == "Red" ? (
//           <RedNotification className={"h-6 w-6 relative -top-3px"} />
//         ) : notifications?.Color == "Green" ? (
//           <GreenNotification className={"h-6 w-6 relative -top-3px"} />
//         ) : (
//           <AmbernNotification className={"h-6 w-6 relative -top-3px"} />
//         )}

//         <div
//           className={`${
//             notifications?.Color == "Red"
//               ? "text-card-redNoti"
//               : notifications?.Color == "Green"
//               ? "text-card-greenNoti"
//               : "text-card-amberNoti"
//           } font-bold leading-20 rounded-md flex flex-row ml-1`}
//         >
//           <p className="text-sm w-1/4 cursor-pointer" title="Go to Dashboard" onClick={() => gotoDashboard(notifications)}>
//             {toPascalCase(notifications?.Client?.replaceAll("_", " ")) + ":"}&nbsp;
//           </p>
//           <div
//             className="w-3/4 cursor-pointer"
//             dangerouslySetInnerHTML={{
//               __html: notifications?.NotificationLabel?.replace(
//                 "{sumetricsvalue}",
//                 notifications?.SubMetricsLabel
//               ),
//             }}
//             title="Mark as read"
//             onClick={() => markAsRead(notifications)}
//           ></div>
//           <div className="mr-2 cursor-pointer hover:bg-slate-700 h-fit p-[6px] hover:rounded-md" title="Delete" onClick={() => deleteNotification(notifications)}>
//             <DeleteIcon className="m-auto " fill='white' />
//           </div>
//         </div>
//       </div>
//       <div className="text-sm border-t-borderTop border-gray-500 my-2"></div>
//     </div>
//   );
// };

// export default NotificationsData;

import { toPascalCase, getResource } from "../../../utils/functionUtils";
import GreenNotification from "../../../assets/icons/greenNot";
import RedNotification from "../../../assets/icons/redNot";
import AmbernNotification from "../../../assets/icons/amberNot";
import config from "../../../config/config";

const NotificationsData = ({ notifications, resources }) => {
  return (
    <div className="flex flex-col">
      {config.isSGP === 'false' ?
      <div className="flex flex-row justify-between">
      {notifications?.Color == "Red" ? (
        <RedNotification className={"h-6 w-6 relative -top-3px"} />
      ) : notifications?.Color == "Green" ? (
        <GreenNotification className={"h-6 w-6 relative -top-3px"} />
      ) : (
        <AmbernNotification className={"h-6 w-6 relative -top-3px"} />
      )}

      <div
        className={`${
          notifications?.Color == "Red"
            ? "text-card-redNoti"
            : notifications?.Color == "Green"
            ? "text-card-greenNoti"
            : "text-card-amberNoti"
        } font-bold leading-20 rounded-md flex flex-row ml-1`}
      >
        <p className="text-sm w-1/4">
          {toPascalCase(notifications?.Client?.replaceAll("_", " ")) + ":"}&nbsp;
        </p>
        <div
          className="w-3/4"
          dangerouslySetInnerHTML={{
            __html: notifications?.NotificationLabel?.replace(
              "{sumetricsvalue}",
              notifications?.SubMetricsLabel
            ),
          }}
        ></div>
      </div>
    </div>
    :
          <p className="text-black">
            {notifications?.NotificationLabel}
          </p>
      }
      <div className="text-sm border-t-borderTop border-gray-500 my-2"></div>
    </div>
  );
};

export default NotificationsData;
