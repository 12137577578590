import React, { useEffect, useState } from "react"
import ProgramCard from '../../assets/icons/programCards'
import { useCentralStore } from "../../store/appContext"
import { toPascalCase } from "../../utils/functionUtils"
import { isEmpty } from "../../utils/functionUtils"

const ProgramCards = ({ actionTypes, dispatch, clientName, clientObject, programData, resources }) => {

    const [ {clientList, allDashboardsList, uniqueClientsList, mapSelected, selectedTab} ] = useCentralStore()
    
    let clientId = clientList?.filter(c => toPascalCase(c?.name?.toLowerCase()?.replaceAll("_", " ")) === toPascalCase(clientName?.toLowerCase()?.replaceAll("_", " ")))
    let clientNameList = []
    clientNameList.push(clientName)

    useEffect(() => {
      if(!isEmpty(uniqueClientsList) && !isEmpty(clientId)){
        let allDashboardsArray = []
        // uniqueClientsList?.filter(v => v?.ClientId == clientId?.[0]?.value)[0]?.Channels?.map(v => v?.AllDashboards?.map(a => allDashboardsArray.push(a)))
        // let allDashboards = allDashboardsArray?.filter((v,i,a)=>a.findIndex(v2=>(v2.Label===v.Label))===i)  
        uniqueClientsList?.map(c => allDashboardsArray.push(c?.Channels?.[0]?.AllDashboards))
        let allDashboards = allDashboardsArray.flat()?.filter((v,i,a)=>a.findIndex(v2=>(v2.Label===v.Label))===i)
        dispatch({ type: actionTypes.SET_ALL_DASHBOARDS, payload: allDashboards })
      }
    }, [uniqueClientsList])

    const gotoDashBoard = (name) => {
      dispatch({ type: actionTypes.GO_TO_MAPS_PAGE, payload: false });
        dispatch({ type: actionTypes.SET_PROGRAM_NAME, data: name });
        dispatch({ type: actionTypes.SET_SELECTED_FAV_DATA, data: undefined })
        // dispatch({ type: actionTypes.SET_SELECTED_TAB, payload: allDashboardsList?.[0]?.Label });
        if(mapSelected?.toLowerCase() === 'singapore'){
          if(name?.toLowerCase() === 'device protection'){
            let clientDashboardLabel = client_name?.toUpperCase() === 'SINGTEL' ? 'Singtel BPM Performance' :
            client_name?.toUpperCase() === 'STARHUB' ? 'StarHub BPM Performance' :
            client_name?.toUpperCase() === 'M1' ? 'M1 BPM Performance' : undefined
            let dashBoard = allDashboardsList?.filter(v => v?.Label === clientDashboardLabel)
            dispatch({ type: actionTypes.SET_SELECTED_TAB, payload: dashBoard?.[0]?.Label });
          }else if(name?.toLowerCase() === 'home plus'){
            let dashBoard = allDashboardsList?.filter(v => v?.Label === 'Home Plus BPM Performance')
            dispatch({ type: actionTypes.SET_SELECTED_TAB, payload: dashBoard?.[0]?.Label });
          }
        }else if(mapSelected?.toLowerCase() === 'thailand') {
          if(name?.toLowerCase() === 'device protection'){
            let clientDashboardLabel = client_name?.toUpperCase() === 'AIS' ? 'AIS BPM Performance' :
            client_name?.toUpperCase() === 'TRUE' ? 'TRUE BPM Performance' : 'Asurion_TechCare BPM Performance'
            let dashBoard = allDashboardsList?.filter(v => v?.Label === clientDashboardLabel)
            dispatch({ type: actionTypes.SET_SELECTED_TAB, payload: dashBoard?.[0]?.Label });
          }
        } else if(mapSelected?.toLowerCase() === 'taiwan') {
          if(name?.toLowerCase() === 'device protection'){
            let clientDashboardLabel = client_name?.toUpperCase() === 'FET' ? 'FET BPM Performance' : undefined
            let dashBoard = allDashboardsList?.filter(v => v?.Label === clientDashboardLabel)
            dispatch({ type: actionTypes.SET_SELECTED_TAB, payload: dashBoard?.[0]?.Label });
          }
        } else {
          dispatch({ type: actionTypes.SET_SELECTED_TAB, payload: allDashboardsList?.[0]?.Label });
        }
        dispatch({ type: actionTypes.SELECTED_CLIENT, payload: clientId?.[0]?.value });
        dispatch({ type: actionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS, payload: false })
    }
    const [client_name, setClientName] = useState(undefined)
    const enablePrograms = mapSelected?.toLowerCase() === 'singapore' || (mapSelected?.toLowerCase() === "malaysia" && client_name?.toLowerCase() === 'celcom') || mapSelected?.toLowerCase() == 'thailand' || (mapSelected?.toLowerCase() === "taiwan" && client_name?.toLowerCase() === 'fet')

    return(
        <>
        {programData?.map(prg =>
          <div onMouseOver={() => setClientName(clientName)}
            onMouseOut={()=> setClientName(undefined)}
            className={`relative my-5 ${enablePrograms ? 'cursor-pointer transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125' : prg?.Program === 'Device Protection' ? 'cursor-not-allowed' : 'cursor-pointer transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125'}`} 
            onClick={() => enablePrograms ? gotoDashBoard(prg?.Program) : prg?.Program === 'Device Protection' ? undefined : gotoDashBoard(prg?.Program)}>
            <p
              className={`absolute text-xs text-center`}
              style={{
                top: "50%",
                right: "50%",
                transform: "translate(50%, -50%)",
                width: 'max-content'
              }}
            >
              {prg?.Program}
            </p>
            <ProgramCard className="ml-1" fill={prg?.Color?.toLowerCase() === 'green' ? '#37E7A7' : prg?.Color?.toLowerCase() === 'red' ? '#DB1B1B' : '#EDB800'} />
          </div>
        )}
          {/* <div className="relative my-5 cursor-pointer" onClick={() => gotoDashBoard('Device Protection')}>
            <p
              className={`absolute text-xs text-center`}
              style={{
                top: "50%",
                right: "50%",
                transform: "translate(50%, -50%)",
              }}
            >
              Device Protection
            </p>
            <ProgramCard className="" fill="#37E7A7"/>
        </div> */}
        {/* <div className="relative my-5 cursor-pointer" onClick={() => gotoDashBoard('Trade-In')}>
          <p
            className={`absolute text-xs text-center`}
            style={{
              top: "50%",
              right: "50%",
              transform: "translate(50%, -50%)",
            }}
          >
            Trade-In
          </p>
          <ProgramCard className="" 
          fill="#37E7A7" /> */}
          {/* fill="#F9D51C" /> */}
        {/* </div> */}
        </>
    )
}

export default ProgramCards