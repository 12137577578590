import BPMImg from "../../assets/homeImg/BPM_home_img";
import TransactionalReportingImg from "../../assets/homeImg/Transactional_Reporting_home_img";
import ApplicationHealthMonitoringImg from "../../assets/homeImg/Application_Health_Monitoring_home_img";
import API from "../../api";
import { useCentralStore } from "../../store/appContext";
import { getUniqueValuesFromArray, isEmpty, toPascalCase } from "../../utils/functionUtils";
import config from "../../config/config";
import { useEffect, useState } from 'react'
import { ReactComponent as Logo } from '../../assets/icons/OneOplogo.svg'
import StarhubLogo from '../../assets/icons/starhubLogo'
import ProgramCard from '../../assets/icons/programCards'
import ReportSGP from "../../pages/operationalReport/ReportSGP";
import moment from "moment";
import SGPLanding from "./SGPLanding";

const LandingPage = ({ ActionTypes, resources }) => {
  const [{ userData, userDetails, clientData, MasterData, country_Data, unique_Clients, clientsResultData, reportFilterDuration }, dispatch] = useCentralStore();

  const setProjectName = (name) => {
    dispatch({ type: ActionTypes.SET_PROJECT_NAME, payload: name });
  };

  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING, payload: action });
  };

  const goToTransaction = (isSGP) => {
    if(isSGP === 'true'){

    } else {
      let payload = {
        idToken: userData?.access_token,
      };
      setLoading(true);
      API[ActionTypes.GET_TRANSACTION_REPORTS](payload, userDetails && userDetails?.email?.split("@")?.[0])
        .then(({ data }) => {
          setLoading(false);
          if (data) {
            dispatch({
              type: ActionTypes.GET_TRANSACTION_REPORTS_SUCCESS,
              payload: data?.TransactionalReportsResponse?.DataSourceList,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: ActionTypes.GET_TRANSACTION_REPORTS_FAILURE,
            payload: err,
          });
          dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
          let _payload = {
            level: resources.POST_LOGGER_ERROR,
            category: resources.POST_LOGGER_API,
            ApiName: resources.POST_LOGGER_APINAME,
            message: {
              error: err,
              info: {
                idToken: "",
              },
            },
          };
          API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
        });
    }
  };

  const [starhubData, setStarhubData] = useState(null)

  useEffect(() => {
    dispatch({ type: ActionTypes.SET_COUNTRYDATA, payload: MasterData?.filter((u) => 'Singapore' === u?.Country)})
  }, [MasterData])

  useEffect(() => {
    if (!isEmpty(country_Data)) {
      dispatch({ type: ActionTypes.SET_UNIQUE_CLIENTS, payload:  getUniqueValuesFromArray(country_Data, "ClientName")?.map((v) => v?.ClientName)})

      let result = country_Data.reduce(function (r, a) {
        r[a.ClientName] = r[a.ClientName] || [];
        r[a.ClientName].push({ Program: a.Program, Color: a.CountryColor });
        return r;
      }, Object.create(null));
      dispatch({ type: ActionTypes.SET_PROGRAM_DATA, payload: result})
    }
  }, [country_Data]);

  useEffect(() => {
    if(!isEmpty(unique_Clients) && !isEmpty(clientsResultData)){
      let unique_Clients_name = unique_Clients?.map(c => c?.toLowerCase()?.replace(' ',''))
      dispatch({ type: ActionTypes.SET_UNIQUE_CLIENS_DATA, payload: clientsResultData.filter(c => unique_Clients_name.includes(c.Client?.toLowerCase()?.replace(' ','')))})
    }
  }, [unique_Clients, clientsResultData])

  useEffect(() => {
    if(!isEmpty(clientData)){
      setStarhubData(clientData?.filter(v => v?.ClientId === "9B945813D41E11E7AA4B0A86007B17AC")?.[0]?.Channels?.[0]?.AllDashboards)
    }
  }, [clientData])

  const gotoDashboard = (name, obj) => {
    
    // let dashboardData = name === 'Device Protection' ? starhubData?.filter(v => v.Label === 'StarHub BPM Performance') : starhubData?.filter(v => v.Label === 'Home Plus BPM Performance')
    // let dashboardData = name === 'Service Request Report' ? starhubData?.filter(v => v.Label === 'StarHub BPM Performance') : starhubData?.filter(v => v.Label === 'Home Plus BPM Performance')
    
    // dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: dashboardData[0]?.Label });
    // dispatch({ type: ActionTypes.SET_SELECTED_DASHBOARD, payload: dashboardData[0]?.DashboardId});
    dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: name });
    // dispatch({ type: ActionTypes.SET_MAP_SELECTED, payload: 'Singapore' });
    dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: '9B945813D41E11E7AA4B0A86007B17AC' });
    // dispatch({ type: ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS, payload: false })
    dispatch({ type: ActionTypes.GO_TO_MAPS_PAGE, payload: false });
    dispatch({ type: ActionTypes.SET_PROGRAM_NAME, data: name });
    dispatch({ type: ActionTypes.SET_SELECTED_FAV_DATA, data: undefined })
    dispatch({ type: ActionTypes.SET_PROJECT_NAME, payload: name });
    dispatch({ type: ActionTypes.SET_PROJECT_SUB_MENU, payload: obj });

    if(name === 'Real Time Service Request Report') {
      dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: obj?.subLabels?.[0]?.value });
      let startDate = moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD 00:00:00')
      let endDate = moment(new Date()).format('YYYY-MM-DD 23:59:59')

      dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION, payload: {
          type: 'dates',
          startDate: startDate,
          endDate: endDate,
          duration: 'dates'
      }})
    } else if ((name === 'Customer Service SLA Report') || name === ('Technical Service SLA Report') || name === ('Finance Reconciliation & Invoice')) {
      const date = new Date()
      const month = '0'+date.getMonth()
      const year = date.getFullYear()
      // console.log('reportFilterDuration', month, ' - ', year, reportFilterDuration);

      dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION, payload: {
        type: 'month',
        startDate: undefined,
        endDate: undefined,
        duration: year + '-' + month
    }})

      let payload = {
        idToken: userData?.access_token,
        month: year + '-' + month
      };

      setLoading(true);  
      API[ActionTypes.GET_SLA_REPORTS_DETAILS](payload, userDetails && userDetails?.email?.split("@")?.[0])
        .then(({ data }) => {
          setLoading(false);  
          if (data) {
            dispatch({
              type: ActionTypes.GET_SLA_REPORTS_DETAILS_SUCCESS,
              payload: data,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: ActionTypes.GET_SLA_REPORTS_DETAILS_FAILURE,
            payload: err,
          });
          dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
          let _payload = {
            level: resources.POST_LOGGER_ERROR,
            category: resources.POST_LOGGER_API,
            ApiName: resources.POST_LOGGER_APINAME,
            message: {
              error: err,
              info: {
                idToken: "",
              },
            },
          };
          API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
        }
      )
    }

    if(name === 'Customer Service SLA Report') {
      dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: obj?.subLabels?.[0]?.value });
    }

    if(name === 'Technical Service SLA Report') {
      dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: obj?.subLabels?.[0]?.value });
    }

    if(name === 'Program Summary'){
      let payload = {
        // Username: userDetails && userDetails?.email?.split("@")?.[0],
        idToken: userData?.access_token,
      };

      setLoading(true);
      API[ActionTypes.GET_CHART_COUNT_DATA](payload, userDetails && userDetails?.email?.split("@")?.[0])
        .then(({ data }) => {
            setLoading(false);
            if (data) {
            dispatch({
                type: ActionTypes.GET_CHART_COUNT_DATA_SUCCESS,
                payload: data,
            });
            }
        })
        .catch((err) => {
            dispatch({
            type: ActionTypes.GET_CHART_COUNT_DATA_FAILURE,
            payload: err,
            });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
        level: resources.POST_LOGGER_ERROR,
        category: resources.POST_LOGGER_API,
        ApiName: resources.POST_LOGGER_APINAME,
        message: {
            error: err,
            info: {
            idToken: "",
            },
        },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
    });
    }

  }

  const SGPProgramCard = ({ pgmName }) => {
    return(
      <div className={`relative my-3 cursor-pointer transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125`} 
      onClick={() => gotoDashboard(pgmName)}>
            <p
              className={`absolute text-xs text-center`}
              style={{
                top: "50%",
                right: "50%",
                transform: "translate(50%, -50%)",
                width: 'max-content'
              }}
            >
              {pgmName}
            </p>
          <ProgramCard className="ml-1" fill={'#6B8BFF'} />
        </div>
    )
  }
  return (
    <>
    {/* {config.isSGP === 'true' &&
      <div className="flex flex-row items-center pt-2">
        <StarhubLogo className="w-14 h-[50px] mr-3" />
        <p className="text-xl font-semibold text-black">Device Protection Portal</p>
      </div>
    } */}
    <div className={`flex flex-col items-center justify-center ${config.isSGP === 'true' ? '' : 'mt-36'} h-4/5`}>
      {config.isSGP === 'false' &&
        <div className="flex flex-col items-center justify-center mb-9">
          <p className={`mb-4 text-4xl text-center ${config.isSGP === 'true' ? 'text-black hidden' : ''}`}>
            {resources.LANDING_HEADER.replace(
              "{username}",userDetails?.firstName?
              toPascalCase(userDetails?.firstName):""
            )}
          </p>
          <p className={` mb-2 text-xl text-center`}>
            {config.isSGP === 'true' ? '' : resources.LANDING_SUB_HEADER}
          </p>
        </div>
      }
      <div className={`flex flex-row flex-wrap justify-center ${config.isSGP === 'false' ? 'mt-10' : 'mt-5 w-full'}`}>
          <> 
          {config.isSGP === 'true' &&
            <SGPLanding 
              resources={resources}
              gotoDashboard={(pgmName, obj) => gotoDashboard(pgmName, obj)}
              userDetails={userDetails}
            />
          }
          {config.isSGP === 'false' && userDetails && !resources.M1_Portal_Users?.map((user)=>user?.toLowerCase())?.includes(userDetails?.email?.toLowerCase()) &&
            <>
            <div
              className="flex flex-col items-center justify-center transition duration-500 ease-in-out cursor-pointer landingScreenStyle p-7 hover:p-6 rounded-xl delay-350 hover:-translate-y-0 hover:scale-y-125 hover:scale-x-125"
              onClick={() => setProjectName(resources.BPM_PROJECT_NAME)}
            >
              <p className="mb-3">{resources.BPM_PROJECT_NAME}</p>
              <BPMImg className="" />
            </div>
            <div
              className="flex flex-col items-center justify-center mx-24 transition duration-500 ease-in-out cursor-pointer landingScreenStyle p-7 hover:p-6 rounded-xl delay-350 hover:-translate-y-0 hover:scale-y-125 hover:scale-x-125"
              onClick={() => {
                setProjectName(resources.TRANSACTIONAL_REPORTING_PROJECT_NAME);
                goToTransaction(config.isSGP);
              }}
            >
              <p className="mb-3">
                {resources.TRANSACTIONAL_REPORTING_PROJECT_NAME}
              </p>
              <TransactionalReportingImg className="" />
            </div>
            <div
              className="flex flex-col items-center justify-center cursor-not-allowed landingScreenStyle1 p-7 rounded-xl"
            // onClick={() => setProjectName(resources.APPLICATION_HEALTH_MONITORING_PROJECT_NAME)}
            >
              <p className="mb-3">
                {resources.APPLICATION_HEALTH_MONITORING_PROJECT_NAME}
              </p>
              <ApplicationHealthMonitoringImg className="" />
            </div>
          </>
          }
          </>
        
        {userDetails && resources.M1_Portal_Users?.map((user)=>user?.toLowerCase())?.includes(userDetails?.email?.toLowerCase()) &&
          <div
            className="flex flex-col items-center justify-center mx-10 transition duration-500 ease-in-out cursor-pointer landingScreenStyle p-7 rounded-xl delay-350 hover:-translate-y-0 hover:scale-y-125 hover:scale-x-110"
            onClick={() =>
              setProjectName(resources.Billing_Data)
            }
          >
            <p className="mb-3">
              M1 Billing Portal
            </p>
            <TransactionalReportingImg className="" />
          </div>}
      </div>
    </div>
    </>
  );
};

export default LandingPage;
